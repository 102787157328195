  <div class="time-block">
      <div>
        <div>{{days}}</div>
        <p>Days</p>
      </div>
      <div>
        <div>{{hours}}</div>
        <p>Hours</p>
      </div>
      <div>
        <div>{{minutes}}</div>
        <p>Minutes</p>
      </div>
      <div>
        <div>{{seconds}}</div>
        <p>Seconds</p>
      </div>
  </div>
