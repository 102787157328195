import { Component, Input, OnInit } from '@angular/core';
export interface ICatItem {
  name: string,
  number: string,
  rarity: string,
  imgLink: string,
  description?: string,
  foreignDescription?: string,
  url?: string
}

@Component({
  selector: 'app-cat-story',
  templateUrl: './cat-story.component.html',
  styleUrls: ['./cat-story.component.scss']
})
export class CatStoryComponent implements OnInit {

  /**
   * Seznam mačjih zgodbic uporabnikov
   */
  @Input() public catStory!: ICatItem;

  /**
   * Razporedi mačke (leva/desna slika) na podlagi Soda in Liha števila
   */
  @Input() public even: any;

  /**
   * UI Če je mačka zadnja na seznamu, potem ne doda material divider črte
   */
  @Input() public last: any;

  constructor() {

   }

  ngOnInit() {
  }

}
