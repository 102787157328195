import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CatService, IHeroCat } from 'src/app/core/services/cat.service';
import { FilterCatsService, ICatsFilter } from 'src/app/pages/my-cats/filter-cats.service';

@Component({
  selector: 'app-page-presentation-card',
  templateUrl: './page-presentation-card.component.html',
  styleUrls: ['./page-presentation-card.component.scss']
})
export class PagePresentationCardComponent implements OnInit {

  @Input() item: any;
  @Input() itemId: string = "";
  @Input() amountMinted: string = "";
  @Input() showMenu: boolean = false;
  public catItem!: IHeroCat;
  public filter: ICatsFilter;
  public showCat: boolean = true;
  public amount:number = 0;
  public checkMode: boolean = false;
  public isChecked: boolean = false;
  public isDisabled: boolean = false;

  constructor(
    private catService:CatService,
    public filterService: FilterCatsService,
    public router: Router
  ) {
    this.filter ={
      mining: "",
      rarity: [""],
      checkboxes: false
    };
  }

  public onClick(event:any) {
    if (this.isDisabled) {
      event.stopPropagation();
      return false;
    }
    if (this.checkMode == false) {
      return true;
    } else {
      this.toggleChecked();
      event.stopPropagation();
      return false;
    }
  }

  public startTransfer() {
    this.filterService.showCheckboxes("TRANSFER");
    this.setChecked(true);
  }

  public startCollecting() {
    this.filterService.showCheckboxes("COLLECT");
    this.setChecked(true);
  }

  public setChecked(newValue:boolean) {
    if (!this.showMenu) {
      return;
    }
    this.item.checked = newValue;
    this.isChecked = newValue;
    this.filterService.triggerChecksChanged();
  }

  public toggleChecked() {
    this.setChecked(!this.isChecked);
  }

  ngOnInit(): void {
    this.amount = Number(this.amountMinted);
    this.catService.getHeroCat(this.itemId).subscribe((item: IHeroCat) => {
      this.catItem = {
        ...item,
        number: this.itemId,
      };
    })

    this.filterService.currentFilter.subscribe((filter: ICatsFilter) => {
      this.filter = filter
      this.checkMode = filter.checkboxes;
      if (this.checkMode == false) {
        this.setChecked(false);
        this.isDisabled = false;
      } else {
        this.isDisabled = (filter.actionType == "COLLECT" && this.amount == 0);
      }
      if(this.filterByMining(filter) && this.filterByRarity(filter)) {
        this.showCat = true;
      } else {
        this.setChecked(false)
        this.isDisabled = false;
        this.showCat = false;
      }
    })

  }

  filterByRarity(filter: ICatsFilter): boolean {
    if(filter?.rarity.length > 0) {
      if(filter.rarity.includes(this.catItem?.attributes[0]?.value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  filterByMining(filter: ICatsFilter): boolean {
    if(!filter.mining || filter?.mining == "" || filter?.mining == "all") {
      return true;
    }
    if((filter.mining == 'yes') &&  (this.catItem?.attributes[9]?.value === "Yes")) {
        return true;
    }
    if (filter.mining == 'no' && (this.catItem?.attributes[9]?.value === "No")) {
      return true;
    }
    return false;
  }

}
