import { Component, OnInit } from '@angular/core';
import { CatService } from 'src/app/core/services/cat.service';
import { ICatItem } from './cat-story/cat-story.component';

@Component({
  selector: 'app-cat-stories',
  templateUrl: './cat-stories.component.html',
  styleUrls: ['./cat-stories.component.scss']
})
export class CatStoriesComponent implements OnInit {

  public catStories: ICatItem[] = [];

  constructor(private catService: CatService) { }

  ngOnInit() {
    this.catService.getCatStories().subscribe(data => this.catStories = data);
  }
}
