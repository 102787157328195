import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { HotCatStatus, HotCatService } from 'src/app/core/services/hot.cat.service';
import { KaikasService, LoginInfo } from 'src/app/core/services/kaikas.service';

@Component({
  selector: 'app-justplayanything',
  templateUrl: './justplayanything.component.html',
  styleUrls: ['./justplayanything.component.scss']
})
export class JustplayanythingComponent implements OnInit {
  private contractAddress:string = "0xaFBf16E126307ee1D6c89f1101AC15e712a391AF";
  private onStatus?:Subscription;
  private onLoginSubscription?:Subscription;

  public hasAllowance:boolean = false;
  public addressAllowance:string = "";
  public status:Observable<HotCatStatus>;
  public currentUser:string = "";


  displayedColumns: string[] = ['address', 'timeHold', 'CATC'];

  constructor(
    private hotCatService: HotCatService,
    private kaikas: KaikasService
  ) { }

  public bid() {
    this.hotCatService.bid(this.contractAddress);
  }

  public approve() {
    this.hotCatService.makeAllowance(this.contractAddress).pipe(take(1)).subscribe({
      complete: ()=>{
        this.refreshAllowance();
      }
    });
  }

  public refresh() {
    this.hotCatService.refreshStatus(this.contractAddress);
  }

  ngOnInit() {
    this.status = this.hotCatService.status(this.contractAddress)!;
    this.onStatus = this.hotCatService.status(this.contractAddress)?.subscribe((data:HotCatStatus)=>{

    });

    this.hotCatService.refreshStatus(this.contractAddress);
    this.onLoginSubscription = this.kaikas.loginInfoObserver.subscribe((loginInfo:LoginInfo)=>{
      this.currentUser = loginInfo.address;
      if (this.hasAllowance == false || this.addressAllowance != this.currentUser)
        this.refreshAllowance();
    });

  }

  public refreshAllowance() {
    this.hotCatService.hasAllowanceToBid(this.contractAddress).pipe(take(1)).subscribe((hasAllowance)=>{
      this.hasAllowance = hasAllowance;
      if (hasAllowance == true)
        this.addressAllowance = this.currentUser;
    });
  }

  ngOnDestroy() {
    this.onStatus?.unsubscribe();
    this.onLoginSubscription?.unsubscribe();
  }

}
