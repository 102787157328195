<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
    <h1 class="main-title">Mongz</h1>
    <ng-container *ngIf="status | async; else noGame; let st">
      <!--<a href="https://www.oxooxoxx.com/" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/mongz/logo.png" alt="friendly-mongz"><img></a>-->
      <a href="https://www.oxooxoxx.com/" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/mongz/bm-gif.gif" alt="friendly-mongz"><img></a>
        <p><a href="https://www.oxooxoxx.com/" target="_blank" style="text-decoration: underline;">www.oxooxoxx.com</a>
      </p>

      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>20 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.20x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x00268c99318c4e5a4340482af8f44fad1f7fb56c/{{st.rewardIdLongest}}" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">MONGZ</p>
                    <p class="pp-title-o"><span class="pp-number">#{{st.rewardIdLongest}}</span></p>
                  </div>
                  <img src="https://api.cattownpunks.com/api/nft/image/0x00268c99318c4e5a4340482af8f44fad1f7fb56c/{{st.rewardIdLongest}}" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">MONGZ #{{st.rewardIdLongest}}</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x00268c99318c4e5a4340482af8f44fad1f7fb56c/{{st.rewardIdMaxPrice}}" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">MONGZ</p>
                    <p class="pp-title-o"> <span class="pp-number">#{{st.rewardIdMaxPrice}}</span></p>
                  </div>
                  <img src="https://api.cattownpunks.com/api/nft/image/0x00268c99318c4e5a4340482af8f44fad1f7fb56c/{{st.rewardIdMaxPrice}}" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">MONGZ #{{st.rewardIdMaxPrice}}</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>
    <h2 style="margin-bottom: 10px; margin-top: 0px;">스토리에 따른 스페셜 파츠가 등장하는 Mongz의 이야기</h2>
    <h3 style="margin-bottom: 10px; margin-top: 10px;">Birth story of "Mongz" 몽즈의 탄생이야기</h3>

    <p>
      현실에서 비트코인을 시작했던 사람들이 "바나나랜드"로 넘어오기 시작했다. <br>
      그곳은 모든게 퇴화하여 사람들이 원숭이가 된곳이였다.<br>
      첫 만남은 "수줍게" 시작하였고 때론 "화려한" 모습을 보여주기도 하였다.<br>
      시간이 흐르고 원숭이들은 "욕심"과 "욕망"이 들어나기 시작하였고 그렇게 "바나나랜드"에 위기가 다가왔다. 바나나랜드에 정착한 첫번째 원숭이 몽즈들은 위기를 벗어나기 위해 "메타콩즈"를 찾아가게 되었고 메타콩즈의 활약에 힘입어 위기를 벗어나게 되었다. 훗날 이 이야기는 클레이튼의 이더리움 대항기로 불리게 된다.<br>
      이 모습을 바라보던 몽즈들은 "영웅"을 꿈꾸게되며 더 강한 DNA를 만들어내기 위해 아이를 낳기 시작하였고 2022년 2월22일 22시22분에 태어나기 시작하였다.<br>
      어느 아이는 비트코인을 좋아하며 차트를 분석하고 어느 아이는 욕심이 많아서 이것저것 소유하고 싶어한다. 자연그대로를 선호하여 옷을 벗고 다니기도 하며 수줍음이 많은 아이는 수줍게 웃으며 안아달라한다.<br>
      어떤DNA를 이어받은 아이가 어떤 베이지몽즈로 태어날지 !<br>
      그 아이가 앞으로 어떤 이야기를 만들어나갈지 ! To be Contunued !
    </p>

    <h3 style="margin-bottom: 10px; margin-top: 10px;">★ 첫번째 이야기 < 대전쟁의 시작 > <br> "바나나랜드"에 위기가 찾아왔다!</h3>

    <p>
      해외 원숭이들이 이더리움으로 무장을 하여 쳐들어온 것이었다.<br>
      이에 위기감을 느낀 몽즈는 바나나랜드의 대장 메타콩즈를 찾아가 도움을 요청하였다.<br>
      치열한 혈투 끝에 메타콩즈가 이더리움을 밀어내었고 "바나나랜드"에 평화가 찾아오게 되었다.<br>
      이를 바라보던 몽즈들은 메타콩즈의 활약을 보며<br>
      가슴이 두근대기 시작하였고 그로부터 부자가 되길 영웅이 되길 갈망하였다.<br>
      여러 베이비몽즈들은 용사가 되기를 꿈꾸게 되며 여러 DNA가 꿈틀대기 시작한다<br>

    </p>

    <h3 style="margin-bottom: 10px; margin-top: 10px;">★ 첫번째 이야기 < 대전쟁의 시작 > <br> "바나나랜드"에 위기가 찾아왔다!</h3>

    <p>
      한 베이비몽즈는 메타콩즈의 활약을 보며 용사가 되길 꿈꾸었고 100명의 팀을 꾸려서 해외 원숭이들에게 역공을 계획하였다.
      To be continued
    </p>

    <h3 style="margin-bottom: 10px; margin-top: 10px;">메인스토리로 진행되는 BABYMONGZ의 스페셜 파츠 등장</h3>

    <img src="./assets/mongz/bm-og.png" class="">

    <p>
      서브스토리로 진행되는 3D 콜라보 아트웍
    </p>

    <p>
      MONGZ 홀더들에게 앞으로 채굴될 MONGZ토큰으로 지재권을 구매하여, 오프에서 사업할 수 있도록 협조 및 플랫폼 개발
    </p>

    <p>
      MONGZ 호텔, 숙박, 여행, 랜트등 오프에서 사용가능한 홀더들을 몽즈회원권 카드를 발급하여, 홀더들간 소속감을 표현!
    </p>

    <p>
      다양한 행사를 통하여, 몽즈 홀더라는 자부심을 가질 수 있는 작은 행사들을 진행!
    </p>

    <br>
    <p><a href="https://opensea.io/collection/mongz" target="_blank" style="text-decoration: underline;">2D Mongz</a></p>
    <p><a href="https://opensea.io/collection/3dmongz" target="_blank" style="text-decoration: underline;">3D Mongz</a></p>
    <p><a href="https://opensea.io/collection/babymongzofficial" target="_blank" style="text-decoration: underline;">3D Baby Mongz</a></p>
    <p><a href="https://www.oxooxoxx.com/" target="_blank" style="text-decoration: underline;">웹사이트</a></p>
    <p><a href="https://open.kakao.com/o/gdyko6Fd" target="_blank" style="text-decoration: underline;">오픈카톡방</a></p>
    <p><a href="https://mongz.medium.com/" target="_blank" style="text-decoration: underline;">미디움</a></p>
    <p><a href="https://twitter.com/oxooxoxx1" target="_blank" style="text-decoration: underline;">트위터</a></p>




    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
