<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">

    <h1 class="main-title">HOT CAT</h1>
    <ng-container *ngIf="status | async; else noGame; let st">
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame" >
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em" >
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em" >
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.</p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions" fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)"  mat-raised-button color="primary" class="btn-primary" (click)="approve()">APPROVE CONTRACT</button>
            <button *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())" mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions" fxLayoutAlign="center center">
            <b>New game rules! Second highest bidder gets NFT insted of money back!</b>
          </div>
        </ng-template>
      </div>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards" >
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <app-page-presentation-card itemId={{st.rewardIdLongest}}></app-page-presentation-card>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <app-page-presentation-card itemId={{st.rewardIdMaxPrice}}></app-page-presentation-card>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <app-page-presentation-card itemId={{st.rewardIdMaxPrice2}}></app-page-presentation-card>
              <p style="margin-top: 0px;">For 2nd highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
                <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
                <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
                <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
                <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
                <mat-icon *ngIf="player.winner3" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b>  when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>
    <h2 style="margin-bottom: 10px; margin-top: 0px;">STORY</h2>

    <p>
      There was an experiment in the <b>Cat Town Laboratory</b>. 100 cats got a very unusual condition called the <b>HOT CAT</b>. It is
      a condition to resist heat over <b>1000 degrees</b>. Those 100 cats are looking for <b>new owners</b>. But they are very picky.
      They need someone who can <b>deal with their condition</b>. To be a new proud owner of HOT CAT, you need to <b>prove</b> you can
      resist some heat.
    </p>

    <p>
      If you think you are tough you can try to handle it just for <b>a few CATC</b> to cover the potential damages. The rules
      are quite simple, <b>whoever can hold the hot ball of lava the longest wins</b>. Well, and the <b>2 last persons to hold it</b>. So
      yeah there are <b>three winners</b>!
    </p>

    <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

    <ul>
      <li>Starting price is <b>100 CATC</b>.</li>
      <li>Once bought you have <b>3 hours cooldown</b> window before someone else can buy it from you.</li>
      <li>The new price is multiplied <b>by 1.25x</b></li>
      <li>All preveious owners get back the CATC they spent plus extra except of last two (they get HOT CAT nft)</li>
      <li>The reward for winning are <b>HOT CATS NFTs</b> that <b>ARE part</b> of the Cat Town Punks collection.</li>
      <li>It is possible for <b>one winner</b> to get <b>2 rewards</b> (holding the longest / bought last).</li>
      <li>All game participants except of the last two get funds back at the <b>end of the round</b>.</li>
      <li>The game may be extended after the last bid for 3 hours and 10 minutes</li>
    </ul>

    <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

    <mat-divider style="width: 100%;"></mat-divider>
    <h2 class="faq-title">FAQ</h2>
    <div fxLayout="column" fxLayoutAlign="start start" class="faq-container">


      <p class="h-question"><b>Q: Is HOT CAT part of CatTownPunks collection?</b></p>
      <p class="h-answer">A: YES!</p>

      <p class="h-question"><b>Q: How many HOT CATs will be?</b></p>
      <p class="h-answer">It is a very limited and prestigious collection of no more than 100 hot cats.
      </p>

      <p class="h-question"><b> Q: How can a HOT CAT be part of CatTownPunks collection? CTP is limited to 20,000 NFTs!
        </b></p>
      <p class="h-answer">A: We will transform 100 common cats into HOT CATs.
      </p>

      <p class="h-question"><b>Q: Why is HOT CAT so special?</b></p>
      <p class="h-answer">A: HOT CAT is very special. We want early adopters to have a chance to get HOT CAT relatively cheap. So we don’t
        want to release the whole plan. HOT CAT will have a special place in the CTP universe.</p>

      <p class="h-question"><b>Q: Will HOT CAT have any special effects?</b></p>
      <p class="h-answer">A: Not at the beginning.</p>

      <p class="h-question"><b>Q: Is possible to acquire HOT CAT on opensea.io?</b></p>
      <p class="h-answer">A: You should check it on opensea. Choose parameter type and select HOT CAT.</p>

      <p class="h-question"><b>Q: Will there be more types of CatTownPunks?</b></p>
      <p class="h-answer">A: Yes, standard, HOT CAT, and 2 more. None of the new types will be able to mine CatCoin.</p>

      <p class="h-question"><b>Q: Will HOT CAT have a rarity?</b></p>
      <p class="h-answer">A: No, all HOT CATs will be rare. But from its type (new parameter) perspective, it will be very unique.</p>
    </div>

    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
