import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { HotCatComponent } from './pages/activities/hot-cat/hot-cat.component';
import { MintComponent } from './pages/mint/mint.component';
import { MyCatsComponent } from './pages/my-cats/my-cats.component';
import { RoadMapPageComponent } from './pages/road-map-page/road-map-page.component';
import { DiscordVerificationComponent } from './pages/discord-verification/discord-verification.component';
import { DobermanComponent } from './pages/activities/doberman/doberman.component';
import { RoomerClubComponent } from './pages/activities/roomer-club/roomer-club.component';
import { MongzComponent } from './pages/activities/mongz/mongz.component';
import { CollectorDragonComponent } from './pages/activities/collector-dragon/collector-dragon.component';
import { MemeTherapyComponent } from './pages/activities/meme-therapy/meme-therapy.component';
import { JustplayanythingComponent } from './pages/activities/justplayanything/justplayanything.component';
import { Kepler452bComponent } from './pages/activities/kepler452b/kepler452b.component';
import { WhitelistInfoComponent } from './pages/whitelist/whitelist-info/whitelist-info.component';
import { CouponTestComponent } from './pages/coupon-test/coupon-test.component';
import { MyOtherComponent } from './pages/my-other/my-other.component';
import { EventsComponent } from './pages/events/events.component';
import { SandboxComponent } from './pages/sandbox/sandbox.component';
import { ZodiacComponent } from './pages/activities/zodiac/zodiac.component';
import { MyMeYouComponent } from './pages/my-meyou/my-me-you.component';
import { MeYouMintingComponent } from './pages/me-you-minting/me-you-minting.component';
import { CatTiffanyComponent } from './pages/activities/cat-tiffany/cat-tiffany.component';
import { CatTownNovelComponent } from './pages/activities/cat-town-novel/cat-town-novel.component';
import { GrinderCatComponent } from './pages/activities/grinder-cat/grinder-cat.component';


const routes: Routes = [
  { path: "", component: IndexComponent},
  //{ path: "mint", component: MintComponent},
  { path: "roadmap", component: RoadMapPageComponent},
  { path: "hot-cat", component: HotCatComponent},
  { path: "mongz", component: MongzComponent},
  { path: "meme-therapy", component: MemeTherapyComponent},
  { path: "kepler-452b", component: Kepler452bComponent},
  { path: "zodiac", component: ZodiacComponent},
  { path: "cat-tiffany", component: CatTiffanyComponent},
  { path: "grinder-cat", component: GrinderCatComponent},
  { path: "cat-town-novel", component: CatTownNovelComponent},

  {
    path: 'cat-coin',
    loadChildren: () => import('./features/cat-coin/cat-coin.module').then(m => m.CatCoinModule)
  },
  {
    path: "cat-profile/:id",
    loadChildren: () => import('./features/cat-profile/cat-profile.module').then(m => m.CatProfileModule)
  },
  {
    path: "me-you-profile/:id",
    loadChildren: () => import('./features/me-you-profile/me-you-profile.module').then(m => m.CatProfileModule)
  },
  { path: "my-cats", component: MyCatsComponent},
  { path: "my-me-you", component: MyMeYouComponent},
  { path: "me-you-minting", component: MeYouMintingComponent},
  { path: "my-other", component: MyOtherComponent},
  {
    path: 'cat-stories',
    loadChildren: () => import('./features/cat-stories/cat-stories.module').then(m => m.CatStoriesModule)
  },
  { path: "mint-test", component: MintComponent},
  { path: "coupon-test", component: CouponTestComponent},
  { path: "discord-verification", component: DiscordVerificationComponent },
  { path: "whitelist-info", component: WhitelistInfoComponent },
  { path: "events", component: EventsComponent },
  { path: "sandbox", component: SandboxComponent },
];
  // { path: '**', component: PageNotFoundComponent },
  //{ path: 'path/:routeParam', component: MyComponent },
  //{ path: 'staticPath', component: ... },
  //{ path: '**', component: ... },
  //{ path: 'oldPath', redirectTo: '/staticPath' },
  //{ path: ..., component: ..., data: { message: 'Custom' }

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
