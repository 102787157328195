
<div class="mc-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.xs="0px" *ngIf="showCountDown">
  <p class="mc-text-title">Reveal in </p>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.xs="10px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="mc-timer">{{days}}</div>
      <p class="mc-text">d </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="mc-timer">{{hours}}</div>
      <p class="mc-text">h </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="mc-timer">{{minutes}}</div>
      <p class="mc-text">m </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="mc-timer">{{seconds}}</div>
      <p class="mc-text">s </p>
    </div>
  </div>
</div>

<div class="mc-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!showCountDown">
  <p class="mc-text-title">Coming Soon. </p>
</div>
