import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { PageService } from 'src/app/core/services/page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cat-town-novel',
  templateUrl: './cat-town-novel.component.html',
  styleUrls: ['./cat-town-novel.component.scss']
})
export class CatTownNovelComponent implements OnInit {
  public content: string;
  constructor(
    private pageService: PageService,
    private route: ActivatedRoute

  ) { }
  ngOnInit(): void {
    let refresh:string|null = this.route.snapshot.queryParamMap.get('refresh');
    this.pageService.getPage(environment.pages.activites.catTownNovel, (refresh=="true")).pipe(take(1)).subscribe((data)=>{
      this.content = data;
    });
  }

}
