import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DarkRitualService } from 'src/app/core/services/darkritual.service';
import { IKaikasTransaction, KaikasService } from 'src/app/core/services/kaikas.service';
import { CatShopApprovalComponent } from 'src/app/features/cat-profile/cat-shop-approval/cat-shop-approval.component';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-modal-confirm-klay-dark-ritual',
  template: `
    <div fxLayout="column">
      <h1  class="modal-title">{{title}}</h1>
      <div mat-dialog-content class="modal-text" style="text-align: center;">
        <p>Are you sure you want to perform Dark Ritual for <span textColor="accent" style="font-weight: bold;">{{data.price | number: '0.1-6'  }}</span> KLAY?</p>
        <mat-checkbox [formControl]="iamsureFC">Yes, I'm 100% sure i would like to pay with KLAY</mat-checkbox>
      </div>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" class="modal-button">No</button>
        <button mat-raised-button color="primary" class="modal-button" (click)="onYesClick()">Yes</button>
      </div>

      <div *ngIf="isLoading" mat-dialog-actions align="end">
        <p>Please confirm on Kaikas Vallet</p>
      </div>
    </div>
  `,
  styles: [`
    .modal-title {
      margin-top: 0px;
      text-align: center;
    }
    .check-box {
      width: 1.6em;
      height: 1.6em;
    }
    .mat-dialog-content {
      overflow: visible;
    }
    `]
})
export class ModalConfirmKlayDarkRitualComponent implements OnInit {

  public isLoading: boolean = false;
  public userDeniedTx: boolean = false;
  public title: string = "Warning";
  public titleIcon: string = "thumb_up_off_alt";
  public randowTweetHref: string = "";
  public hasAllowance:boolean = false;
  public iamsureFC = new FormControl();

  constructor(
    private snackBar: MatSnackBar,
    private kaikas: KaikasService,
    public dialogRef: MatDialogRef<ModalConfirmKlayDarkRitualComponent>,
    private darkRitualService: DarkRitualService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (!this.iamsureFC.value) {
      this.snackBar.openFromComponent(ResponseSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        duration: 2000,
        data: "NOT SURE?"
      });
      return;
    }


      this.darkRitualService.makeRitualKlay(this.data.catId, this.data.price);
      this.dialogRef.close();
  }

  ngOnInit() {
  }
}
