<div fxLayout="column">
  <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex fxLayoutAlign="center" class="d-title">Error</div>
    <mat-icon color="primary" class="d-icon" fxLayoutAlign="end end">error</mat-icon>
  </h1>
  <app-height-spacer height="1rem"></app-height-spacer>
  <div mat-dialog-content fxLayoutAlign="center">
    <p class="d-text">{{data}}</p>
  </div>
  <app-height-spacer height="0.4rem"></app-height-spacer>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" class="d-button" (click)="onNoClick()">Ok</button>
  </div>
  <app-height-spacer height="1rem"></app-height-spacer>
</div>