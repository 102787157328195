import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICatItem } from 'src/app/features/cat-stories/cat-story/cat-story.component';
import { environment } from 'src/environments/environment';

export interface IHeroCat {
  attributes: IHeroCatAttributes[],
  image: string,
  name: string,
  description: string,
  number?: string,
}

interface IHeroCatAttributes {
  trait_type: string,
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class CatService {
  constructor(
      private httpClient: HttpClient,
      private window: Window
  ) { }
  private heroSubject:Map<number, ReplaySubject<any>> = new Map<number, ReplaySubject<any>>();

  getHeroCatObservable(catId: number):Observable<any> {
    return this._getHeroCatObservable(catId).asObservable();
  }

  private _getHeroCatObservable(catId: number) {
    if (this.heroSubject.get(catId) == undefined) {
      this.heroSubject.set(catId, new ReplaySubject(1));
    }
    return this.heroSubject.get(catId)!;
  }

  refreshHeroCatObservable(catId: number) {
    this.httpClient.get(environment.tokenUrl + '/k-cat-town-punks/' + catId).subscribe((data:any)=>{
      data.image = data.image + "?ac=" + Math.random();
      this._getHeroCatObservable(catId).next(data);
    });
  }

  getHeroCat(id: number|string): Observable<any> {
    return this.httpClient.get(environment.tokenUrl + '/k-cat-town-punks/' + id);
  }

  getCatOwner(id: number): Observable<string> {
    return this.httpClient.get( environment.tokenUrl + "/api/get-owner?cat=" + id,{responseType: 'text'});
  }

  fixMetadata(id: number): Observable<string> {
    return this.httpClient.get( environment.tokenUrl + "/api/refresh-metadata?cat=" + id,{responseType: 'text'});
  }

  changeCatName(body:any): Observable<any> {
    const ep = environment.tokenUrl + "/api/change-name";
    const options = {headers: {'Content-Type': 'application/json'}};
    return this.httpClient.post<string>(ep, JSON.stringify(body), options);
  }

  getLastMintedId() {
    return this.httpClient.get(environment.tokenUrl + '/limit', {responseType: "text"});
  }

  getCatStories(): Observable<ICatItem[]> {
    return this.httpClient.get('assets/cat-stories.json')
          .pipe(map(data => data as Array<ICatItem>));
  }
}
