<div class="top-row">
  <h1 mat-dialog-title >
    {{data.title}}
  </h1>
  <mat-icon color="primary" class="d-icon">info</mat-icon>

</div>

<div mat-dialog-content fxLayoutAlign="center center" fxLayout="column" class="d-content">
  <p class="d-text">{{data.description}}</p>
</div>
<app-height-spacer height="1.4rem"></app-height-spacer>
<div mat-dialog-actions fxLayout="row"  fxLayoutAlign="center center">
  <button mat-raised-button color="primary" class="d-button" mat-dialog-close="approve" (click)="this.onNoClick()">OK</button>
</div>
<app-height-spacer height="1rem"></app-height-spacer>
