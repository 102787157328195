import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-whitelist-info',
  templateUrl: './whitelist-info.component.html',
  styleUrls: ['./whitelist-info.component.scss']
})




export class WhitelistInfoComponent implements OnInit {
  whitelistForm: FormGroup;
  validationErrors: string[] = [];
  roles:any
  address:string
  isSubmitted:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
    ) {
      this.intitializeForm();
    }

  ngOnInit() {
    this.whitelistForm.markAsPristine();
  }

  intitializeForm() {
    this.whitelistForm = this.formBuilder.group({
      userAddress: ['', Validators.required]
    })
  }
  async checkAddress(){
    var apiUrl = environment.tokenUrl + '/whitelist-info/'
    //var apiUrl = 'http://localhost:3100/api' + '/whitelist/'
    this.address = this.whitelistForm.get('userAddress')?.value
    let rsp = await this.httpClient.get(apiUrl + this.whitelistForm.get('userAddress')?.value).toPromise().then(response => {
        return response;
      }).catch((error)=>{console.log("error",error); return false})

    this.roles = rsp

  }

  submitForm() {
    this.isSubmitted = true;
    if (this.whitelistForm.invalid) {
      return
    }

  }

  userAddressControll(){
    return this.whitelistForm.controls["userAddress"] as FormControl
  }

}
