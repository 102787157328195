<div fxLayout="column" fxLayoutAlign="center center">
  <div class="login-card" *ngIf="!kaikasService.loginInfo.isConnected">
    <div style="margin: 2rem;" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/kaikas-logo.png" alt="kaikas-logo" class="login-img">
      <button mat-raised-button color="primary" class="login-button" (click)="kaikasService.login()">LOGIN WITH
        KAIKAS</button>
    </div>
  </div>
  <app-height-spacer height="5rem"></app-height-spacer>
  <br>

  <div *ngIf="kaikasService.loginInfo.isConnected">
    <ng-template [ngIf]="authenticated == undefined">
      <a href="{{authUrl}}"
      style="text-decoration: underline;"><button mat-raised-button color="primary"
       class="btn-primary">Authenticate account for Discord</button></a>
    </ng-template>
    <ng-template [ngIf]="authenticated == true">
      <p>Congratulations you are authenticated. You can now close this tab.</p>
    </ng-template>

  </div>
  <div>
    <app-height-spacer height="5rem"></app-height-spacer>

  </div>
</div>
