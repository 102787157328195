import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'demo-cat-shop-approval',
  templateUrl: './cat-shop-approval.component.html',
  styleUrls: ['./cat-shop-approval.component.scss']
})
export class CatShopApprovalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CatShopApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

  }

}
