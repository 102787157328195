import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CatStoryComponent } from './cat-story/cat-story.component';
import { CatStoriesComponent } from './cat-stories.component';
import { CatStoriesRoutingModule } from './cat-stories-routing.module';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    CatStoriesComponent,
    CatStoryComponent
  ],
  imports: [
    CommonModule,
    CatStoriesRoutingModule,
    SharedModule
   ],
  exports: [
    CatStoryComponent
  ],
  providers: [],
})
export class CatStoriesModule {}
