import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { PageService } from 'src/app/core/services/page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-zodiac',
  templateUrl: './zodiac.component.html',
  styleUrls: ['./zodiac.component.scss']
})
export class ZodiacComponent implements OnInit {
  public content: string;
  constructor(
    private pageService: PageService,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let refresh:string|null = this.route.snapshot.queryParamMap.get('refresh');
    this.pageService.getPage(environment.pages.biddingGames.zodiac, (refresh=="true")).pipe(take(1)).subscribe((data)=>{
      this.content = data;
    });
  }
}