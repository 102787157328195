import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ICatsFilter {
  rarity: String[],
  mining: string,
  checkboxes: boolean,
  actionType?: string
}


@Injectable({
  providedIn: 'root'
})
export class FilterCatsService {
  _value: ICatsFilter = {
    rarity: [],
    mining: "",
    checkboxes: false
  }
  private filterSource = new BehaviorSubject<ICatsFilter>(this._value);
  private checksSource = new BehaviorSubject<string>("");
  currentFilter = this.filterSource.asObservable();
  checkChanged = this.checksSource.asObservable();


  constructor() { }

  changeFilter(rarity: string[], mining: string) {
    this._value.rarity = rarity;
    this._value.mining = mining;
    this.filterSource.next(this._value);
  }

  showCheckboxes(actionType:string) {
    this._value.checkboxes = true;
    this._value.actionType = actionType;
    this.filterSource.next(this._value);
  }

  hideCheckboxes() {
    this._value.checkboxes = false;
    this._value.actionType = undefined;
    this.filterSource.next(this._value);
  }

  triggerChecksChanged() {
    this.checksSource.next("");
  }
}
