import { Component, Input, OnInit, Output } from '@angular/core';
import { KaikasService } from 'src/app/core/services/kaikas.service';

export interface IMarketCountdown {
  title: string,
  text?: string,
  countdown?: Date,
  secondsTillCountdown?: number
}

@Component({
  selector: 'app-market-countdown',
  templateUrl: './market-countdown.component.html',
  styleUrls: ['./market-countdown.component.scss']
})
export class MarketCountdownComponent implements OnInit {
  /**
   * Vnos datuma se vpiše v UTC formatu
   * Npr. countdownDate = new Date(Date.UTC(2021, 9, 26, 14, 0))
   */
  @Input() countdownDate?: Date = new Date;
  @Input() secondsTillCountdown?: number;
  @Input() title: string = "";
  @Input() text: string = "";


  // countdownDate2 = new Date(Date.UTC(2021, 9, 26, 14, 0))
  demo: any
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  showCountDown: boolean = false;

  public coinsLeft: any = "0";

  x = setInterval(() => {

    this.days = Math.floor(this.secondsTillCountdown! / (3600 * 24));
    this.hours = Math.floor(this.secondsTillCountdown! % (3600 * 24) / 3600);
    this.minutes = Math.floor((this.secondsTillCountdown! % 3600) / 60);
    this.seconds = Math.floor(this.secondsTillCountdown! % 60);


    if(this.secondsTillCountdown! <= 0 ) {
      this.showCountDown = false;
    } else {
      this.showCountDown = true;
    }


    /* else if(this.countdownDate) {
        var now = new Date();
        var distance = this.countdownDate.valueOf() - now.valueOf();
        this.days = Math.floor(distance/(1000*60*60*24));
        this.hours = Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
        this.minutes = Math.floor((distance % (1000*60*60) / (1000*60)));
        this.seconds = Math.floor((distance % (1000*60) / 1000));
        if(this.seconds < 0 ) {
          this.showCountDown = false;
        } else {
          this.showCountDown = true;
        }
    } */
    if (this.secondsTillCountdown! > 0)
      this.secondsTillCountdown = this.secondsTillCountdown! - 1;

  }, 1000)

  constructor( private kaikas: KaikasService) {
  }

  ngOnInit() {
    this.kaikas.getPresaleInfo().subscribe(data => {
      this.coinsLeft = data.coinsLeft;
    })
  }



}
