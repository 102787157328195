import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatShopService {

  private filterSource = new BehaviorSubject<String>("");
  currentImage = this.filterSource.asObservable();

  constructor() { }

  changeImage(filter: String) {
    this.filterSource.next(filter);
  }

}
