<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" fixedInViewport autoFocus="false"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      mode="over">
    <mat-toolbar color="primary" style="height: 64px; font-family: Poppins;">Cats Pages</mat-toolbar>
    <!-- SIDENAV MENU -->
    <mat-nav-list (click)="sidenav.toggle()">
      <a mat-list-item routerLink="/" routerLinkActive="active" matRipple [routerLinkActiveOptions]="{ exact: true }" >Cat Town Punks</a>
      <a mat-list-item href="https://opensea.io/collection/cat-town-punks" target="_blank">Cherypick, buy on OpenSea</a>
      <a mat-list-item routerLink="/roadmap" routerLinkActive="active" matRipple>Roadmap</a>
      <a mat-list-item routerLink="/hot-cat" routerLinkActive="active" matRipple>HOT CAT</a>
      <a mat-list-item routerLink="/cat-stories" routerLinkActive="active" matRipple>Cat Stories</a>
      <a mat-list-item routerLink="/me-you-minting">MeYou Minting</a>
      <a mat-list-item routerLink="/cat-tiffany" routerLinkActive="active" matRipple>Cat Tiffany</a>
      <a mat-list-item routerLink="/grinder-cat" routerLinkActive="active" matRipple>Grinder Cat</a>
      <a mat-list-item routerLink="/cat-town-novel" routerLinkActive="active" matRipple>Cat Town Novel</a>
      <a mat-list-item routerLink="/cat-coin" routerLinkActive="active" matRipple>CatCoin</a>
      <a mat-list-item *ngIf="!isConnected" (click)="kaikas.login()">Login with Kaikas</a>

      <mat-accordion *ngIf="(loginInfo|async)!.isConnected == true">
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          class="mobile-expansion-panel"
          (click)="sidenav.toggle()"
          [ngStyle]="{'box-shadow': panelOpenState? '' : 'none' }" >
          <mat-expansion-panel-header style="padding-left: 16px;">
            <mat-panel-title>
              <div class="mobile-panel-title"> {{shortAddress}}</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column" fxLayoutAlign="space-around center">

          <mat-list fxLayout="column" fxLayoutAlign="center center">
            <p class="profile-catcoins">Currency</p>
            <div fxLayout="row" class="profile-coin" fxLayoutAlign="center center" style="padding-bottom: 0;">
              <img src="/assets/catcoin-50X50.png" alt="cat-coin" height="30px" width="30px">
              <p class="profile-coin-text"> {{catCoins | number : '1.2-2'}}</p>
            </div>
            <div fxLayout="row" class="profile-coin" fxLayoutAlign="center center">
              <img src="/assets/items/klay-coin.png" alt="klay-coin" height="30px" width="30px">
              <p class="profile-coin-text" style="color: #4F473C;"> {{klay | number : '1.2-2'}}</p>
            </div>
            <mat-divider style="width: 100%; margin-bottom: 7px;"></mat-divider>
            <button class="profile" mat-button *ngIf="(isConnected)" routerLink="/my-cats">My Mining Cats</button>
            <button class="profile" mat-button *ngIf="(isConnected)" routerLink="/my-me-you">My MeYou</button>
            <button class="profile" mat-button *ngIf="(isConnected)" routerLink="/my-other">Voucher NFTs</button>
            <button class="profile" mat-button style="color: #F9A81B;" *ngIf="isConnected" (click)="openDialogCollectCatCoins('all')">Collect All CatCoins</button>
            <button class="profile" mat-button style="color: #F9A81B;" *ngIf="isConnected" (click)="openDialogCollectCatCoins('top50')">Collect TOP 50</button>
          </mat-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- <mat-progress-bar class="progress-bar" mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar> -->

    <!-- DESKTOP -->
    <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-around center"  *ngIf="(isWeb$ | async)" style="position: fixed; z-index: 980; box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);">
      <a class="header" mat-button routerLink="/" routerLinkActive="" [routerLinkActiveOptions]="{ exact: true }">Cat Town Punks</a>
      <a class="header" mat-button href="https://opensea.io/collection/cat-town-punks" target="_blank">Cherypick, buy on OpenSea</a>
      <a class="header" mat-button routerLink="/roadmap" routerLinkActive="active">Roadmap</a>
      <ng-container *ngTemplateOutlet="activitiesMenu"></ng-container>
      <a class="header" mat-button routerLink="/cat-coin" routerLinkActive="active">CatCoin</a>
      <ng-container *ngTemplateOutlet="meYouMenu"></ng-container>
      <a class="header" mat-button *ngIf="(!this.isConnected)" matRipple (click)="(this.kaikas.login())" style="cursor: pointer;">Login with Kaikas</a>
      <ng-container *ngTemplateOutlet="headerMenu"></ng-container>
    </mat-toolbar>

    <!-- TABLET -->
    <mat-toolbar color="primary" *ngIf="(isTablet$ | async)" style="position: fixed; z-index: 980; box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);">
      <button class="header" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="header" mat-button routerLink="/" routerLinkActive="" [routerLinkActiveOptions]="{ exact: true }">Cat Town Punks</a>
      <span fxFlex></span>
      <a class="header" mat-icon-button href="https://opensea.io/collection/cat-town-punks" target="_blank"><mat-icon svgIcon="open-sea-transparent"></mat-icon></a>
      <a class="header" mat-icon-button *ngIf="(!this.isConnected)" (click)="(this.kaikas.login())"><mat-icon>login</mat-icon></a>
      <ng-container *ngTemplateOutlet="headerMenu"></ng-container>
    </mat-toolbar>

    <!-- MOBILE -->
    <mat-toolbar color="primary" *ngIf="isHandset$ | async" style="position: fixed; z-index: 980; height: 64px; box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <mat-divider vertical class="h-divider__left"></mat-divider>
      <div fxFlex fxLayoutAlign="center center">
        <div class="h-title">{{currentRouteName}}</div>
      </div>
      <mat-divider vertical class="h-divider__right" ></mat-divider>
      <a mat-icon-button href="https://opensea.io/collection/cat-town-punks" target="_blank"><mat-icon svgIcon="open-sea-transparent"></mat-icon></a>
    </mat-toolbar>

    <!-- ROUTER OUTLET -->
    <div fxLayout="row">
      <div fxFlex.xs="16px" fxFlex.sm="32px" fxFlex.md="32px" fxFlex.gt-md="auto"></div>
        <div fxFlex.xs="auto" fxFlex.sm="auto" fxFlex.md="auto" style="margin-top: 64px;">
          <router-outlet></router-outlet>
        </div>
      <div fxFlex.xs="16px" fxFlex.sm="32px" fxFlex.md="32px" fxFlex.gt-md="auto"></div>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Menu outlet -->
<ng-template #headerMenu>
  <button class="header" mat-button [matMenuTriggerFor]="matMenu" (click)="doRefresh()" *ngIf="isConnected">{{shortAddress}}</button>
  <mat-menu #matMenu="matMenu">
    <div fxLayout="row" class="profile-coin" fxLayoutAlign="center center" style="padding-bottom: 0;">
      <img src="/assets/catcoin-50X50.png" alt="cat-coin" height="30px" width="30px">
      <p class="profile-coin-text" > {{catCoins | number : '1.2-2'}}</p>
    </div>
    <div fxLayout="row" class="profile-coin" fxLayoutAlign="center center">
      <img src="/assets/items/klay-coin.png" alt="klay-coin" height="30px" width="30px">
      <p class="profile-coin-text" style="color: #4F473C;"> {{klay | number : '1.2-2'}}</p>
    </div>
    <mat-divider style="margin-bottom: 7px;"></mat-divider>
    <button class="profile" mat-menu-item *ngIf="isConnected" routerLink="/my-cats">My Mining Cats</button>
    <button class="profile" mat-menu-item *ngIf="isConnected" routerLink="/my-me-you">My MeYou</button>
    <button class="profile" mat-menu-item *ngIf="(isConnected)" routerLink="/my-other">Voucher NFTs</button>
    <button class="profile" textColor="accent" mat-menu-item *ngIf="isConnected" (click)="openDialogCollectCatCoins('all')">Collect All CatCoins</button>
    <button class="profile" textColor="accent" mat-menu-item *ngIf="isConnected" (click)="openDialogCollectCatCoins('top50')">Collect TOP 50</button>
  </mat-menu>
</ng-template>

<ng-template #meYouMenu>
  <button class="header" mat-button [matMenuTriggerFor]="activityMenu">MeYou</button>
  <mat-menu #activityMenu="matMenu">
    <a class="profile"  style="width: 200px;" mat-menu-item routerLink="/me-you-minting">Minting</a>
  </mat-menu>
</ng-template>

<!-- Menu outlet -->
<ng-template #activitiesMenu>
  <button class="header" mat-button [matMenuTriggerFor]="activityMenu">Activities</button>
  <mat-menu #activityMenu="matMenu">
    <a class="profile" mat-menu-item routerLink="/cat-tiffany">Cat Tiffany</a>
    <a class="profile" mat-menu-item routerLink="/grinder-cat">Grinder Cat</a>
    <a class="profile" mat-menu-item routerLink="/cat-town-novel">Cat Town Novel</a>
    <a class="profile"  style="width: 200px;" mat-menu-item routerLink="/hot-cat">Hot Cat</a>
    <a class="profile" mat-menu-item routerLink="/cat-stories">Cat Stories</a>
  </mat-menu>
</ng-template>
