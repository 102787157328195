<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
    <h1 class="main-title">Kepler-452b 소개</h1>
    <a href="https://kepler-452b.net" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/kepler/kepler-logo.png" alt="friendly-kepler"><img></a>
    <p>
      Kepler-452b is an evolutionary and proliferative NFT project.<br>
      We welcome you to be a gardener and enjoy your fun and good deeds.
    </p>
  <ng-container *ngIf="status | async; else noGame; let st">
      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>10 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.25x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x928267e7db3d173898553ff593a78719bb16929f/10758" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">Kepler-452b</p>
                    <p class="pp-title-o"><span class="pp-number">#10758</span></p>
                  </div>
                  <img src="./assets/kepler/kepler10758.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Kepler-452b #10758</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x928267e7db3d173898553ff593a78719bb16929f/10760" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">Kepler-452b</p>
                    <p class="pp-title-o"> <span class="pp-number">#10760</span></p>
                  </div>
                  <img src="./assets/kepler/kepler10760.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Kepler-452b #10760</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>


    <h2 style="margin-bottom: 10px; margin-top: 0px;">Kepler-452b 소개</h2>
    <a href="https://kepler-452b.net" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/kepler/kepler-project.png" alt="friendly-kepler"><img></a>
    <p>
      Keplin 팀과 함께 '선한 영향력'을 보여주세요!<br>
      Kepler-452b는 진화 및 증식이 가능한 Evol NFT 프로젝트 입니다.<br>
      매일 일정 확률에 따라 단계별 성장이 이루어지고 새로운 NFT를 증식하는 시스템을 가지고 있습니다.<br>
      실제 날씨(서울특별시 중구)에 따라 진화확률에 영향을 미치는 온오프라인 연계 기술이 적용됩니다.<br>
      귀여운 메타세포는 진화함에 따라 오싹하게 변화하는 매력 넘치는 Evol NFT 입니다.<br>
    </p>

    <h2 style="margin-bottom: 10px; margin-top: 0px;">Kepler-452b 스토리</h2>
    <p>케플러 프로젝트는 오픈형 스토리를 구성하였습니다. 실제로 이루어진 콘텐츠와 환경보호 활동, 커뮤니티 의견을 종합하여 제작됩니다.</p>

    <h3 style="margin-bottom: 10px; margin-top: 0px;">1화. 메타세포의 시작</h3>
    <p>
      2135년, 생태계의 극심한 파괴로 식물 성장이 어려워진 지구를 구하기 위해 Keplin 박사는 팀을 꾸려 유사행성인 ‘Kepler-452b’ 탐사를 실시합니다. <br>
      이 곳에서 채취한 원세포를 연구하여 지구 식물과의 융합을 시도, 이후 새로운 특성을 지닌 외계 세포인 ‘메타세포’ 다섯 종을 융합에 성공합니다.
    </p>

    <p>
      메타세포는 비정상적인 외형과 때로는 공격성을 지녔지만 우주식물이 뿌리내린 토양은 지구식물의 성장을 돕는다는 것을 확인합니다.<br>
      Keplin 박사는 연구비 확보와 지구 생태계 복원을 위해 서울특별시 중구에 있는 케플러연구소에서 민간업체와 개인에게 메타세포를 판매하게 됩니다.
    </p>

    <h3 style="margin-bottom: 10px; margin-top: 0px;">2화. 생태계 복원</h3>
    <p>
      판매된 메타세포는 국내를 넘어 해외로도 퍼져나가 확산 되었고, 판매에 있어 큰 도움을 준 가드너(홀더)에게 세포의 진화를 돕는 포션을 지급합니다. <br>
      진화/증식이 이루어지는 세포는 점차 시간이 흐름에 따라 수가 많아져 타인에게 분양하거나 자연에 방생하는 가드너가 많아졌고, 이는 생태 복원으로 이어지는 선순환이 되었습니다.<br>
      [사단법인, 체인지메이커 식목사업 후원, 200만 원]
    </p>

    <p>
      케플린 박사는 생태복원에 효과적인 메타세포의 기능을 증폭시키기 위해 Kepler-452b 2차 탐사를 실시하였고, 탐사 중에 메타세포를 융합하는 촉매제인 ‘믹스스톤’을 발견합니다.<br>
      케플린 팀은 초기 메타세포보다 진화확률이 높고 포악성을 낮춘 믹스종을 개발하였고, 이를 대량생산 하기 위한 믹스스톤의 채굴 방식을 고안하게 됩니다.
    </p>





    <br>
    <p><a href="https://kepler-452b.net" target="_blank" style="text-decoration: underline;">kepler-452b.net</a></p>
    <p><a href="https://discord.gg/3RMKBRJZXg" target="_blank" style="text-decoration: underline;">Discord</a></p>
    <p><a href="https://twitter.com/Kepler_NFT" target="_blank" style="text-decoration: underline;">Twitter</a></p>





    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
