import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import darkRitualAbi from '../../contract/contract-dark-ritual.abi.json';
import couponAbi from '../../contract/contract-coupon.abi.json';
import { KaikasService } from './kaikas.service';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';



@Injectable({
  providedIn: 'root'
})
export class CouponService {


  constructor(
      private window: Window,
      private kaikasService: KaikasService,
      private snackBar: MatSnackBar,
  ) { }

  private otherItemsSubscription = new ReplaySubject<any[]>(1);

  get otherItems() {
    return this.otherItemsSubscription.asObservable();
  }

  public async fetchItems():Promise<void> {
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe(async (loginInfo)=>{
      if (loginInfo.isConnected == false) {
        this.otherItemsSubscription.next([]);
        return;
      }

      let caver = this.kaikasService.getCaver();
      let contract =  new caver.klay.Contract(couponAbi, environment.contractCoupon, { gasPrice: '25000000000' });
      let retData:any = await contract.call("balancesAll", loginInfo.address);
      let ret:any[] = [];
      for (let i=0; i<retData.length; i++) {
        if (retData[i] > 0) {
          ret.push({id: i, amount: retData[i]});
        }
      }
      //ret.sort((a,b) => Number(b.amountMinted) - Number(a.amountMinted));
      this.otherItemsSubscription.next(ret);
    });
  }

  hasAllowance(operatorContract: string):Observable<boolean> {
    let caver = this.kaikasService.getCaver();

    let ret:Subject<boolean> = new Subject<boolean>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      if (account.isConnected == true) {
        let contract =  new caver.klay.Contract(couponAbi, environment.contractCoupon, { gasPrice: '25000000000' });
        contract.call("isApprovedForAll", account.address, operatorContract).then((allowance:any)=>{
          ret.next(allowance);
        });
      } else {
        ret.next(false);
      }
    });
    return ret.asObservable();
  }

  public makeAllowance(operatorContract:string): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(couponAbi, environment.contractCoupon, { gasPrice: '25000000000' });

      let method = contract.methods.setApprovalForAll(operatorContract, true);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }


  public mint5(): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = this.kaikasService.toWei(500000000);
      let contract =  new caver.klay.Contract(couponAbi, environment.contractCoupon, { gasPrice: '25000000000' });
      let method = contract.methods.mintAdd(account.address, 1, 5);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
        ret.complete();
      });
    });
    return ret.asObservable();
  }
}
