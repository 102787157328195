import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAsSecondsToDays'
})
export class DateAsSecondsToDaysPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let temp = value;

    const seconds = temp % 60;
    temp = Math.floor(temp / 60);
    const minutes: number = temp % 60;
    temp = Math.floor(temp / 60);
    const hours = temp % 24;
    const days = Math.floor(temp / 24);

    let s = "";
    if (days > 0 )
      s += days + " days ";
    if (hours > 0 || s != "")
      s += hours + " h "
    if (minutes > 0 || s != "")
      s += minutes + " min "
    if (seconds > 0 || s != "")
      s += seconds + " sec"
    return s;
  }

}
