import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { MintComponent } from './pages/mint/mint.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MarketCountdownComponent } from './shared/components/market-countdown/market-countdown.component';
import { MyCatsComponent } from './pages/my-cats/my-cats.component';
import { SharedModule } from './shared/shared.module';
import { CatStoriesModule } from './features/cat-stories/cat-stories.module';
import { CoreModule } from './core/core.module';
import { MintDialogComponent } from './shared/components/mint-dialog/mint-dialog.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { PagePresentationCardComponent } from './shared/components/page-presentation-card/page-presentation-card.component';
import { ResponseSnackbarComponent } from './shared/components/response-snackbar/response-snackbar.component';
import { RoadMapComponent } from './pages/road-map-page/road-map/road-map.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ModalCollectAllCatCoinsComponent } from './layout/modal-collect-all-cat-coins/modal-collect-all-cat-coins.component';
import { RoadMapPageComponent } from './pages/road-map-page/road-map-page.component';
import { RoadMapCountdownComponent } from './pages/road-map-page/road-map-countdown/road-map-countdown.component';
import { HotCatComponent } from './pages/activities/hot-cat/hot-cat.component';
import { DiscordVerificationComponent } from './pages/discord-verification/discord-verification.component';
import { DobermanComponent } from './pages/activities/doberman/doberman.component';
import { RoomerClubComponent } from './pages/activities/roomer-club/roomer-club.component';
import { MongzComponent } from './pages/activities/mongz/mongz.component';
import { CollectorDragonComponent } from './pages/activities/collector-dragon/collector-dragon.component';
import { MemeTherapyComponent } from './pages/activities/meme-therapy/meme-therapy.component';
import { JustplayanythingComponent } from './pages/activities/justplayanything/justplayanything.component';
import { Kepler452bComponent } from './pages/activities/kepler452b/kepler452b.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalConfirmDarkRitualComponent } from './layout/modal-collect-all-cat-coins/modal-collect-confirm-dark-ritual';
import { ModalConfirmKlayDarkRitualComponent } from './layout/modal-collect-all-cat-coins/modal-confirm-klay-dark-ritual.component';
import { WhitelistInfoComponent } from './pages/whitelist/whitelist-info/whitelist-info.component';
import { ModalTransferCatsComponent } from './pages/my-cats/modal-transfer-cats/modal-transfer-cats/modal-transfer-cats.component';
import { CouponTestComponent } from './pages/coupon-test/coupon-test.component';
import { MyOtherComponent } from './pages/my-other/my-other.component';
import { OtherItemPresentationCardComponent } from './pages/my-other/other-item-presentation-card/other-item-presentation-card.component';
import { EventsComponent } from './pages/events/events.component';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { PageHookParser } from './shared/parsers/page-hook-parser';
import { LinksHookParser } from './shared/parsers/links-hook-parser';
import { SandboxComponent } from './pages/sandbox/sandbox.component';
import { ZodiacComponent } from './pages/activities/zodiac/zodiac.component';
import { MyMeYouComponent } from './pages/my-meyou/my-me-you.component';
import { MeYouMintingComponent } from './pages/me-you-minting/me-you-minting.component';
import { MeYouCardComponent } from './shared/components/me-you-card/me-you-card.component';
import { CatTiffanyComponent } from './pages/activities/cat-tiffany/cat-tiffany.component';
import { GrinderCatComponent } from './pages/activities/grinder-cat/grinder-cat.component';
import { CatTownNovelComponent } from './pages/activities/cat-town-novel/cat-town-novel.component';

const componentParsers: Array<HookParserEntry> = [
  {component: PagePresentationCardComponent},
  PageHookParser,
  LinksHookParser
];

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FooterComponent,
    MintComponent,
    NotificationComponent,
    RoadMapComponent,
    MintDialogComponent,
    PagePresentationCardComponent,
    MeYouCardComponent,
    ResponseSnackbarComponent,
    MyCatsComponent,
    RoadMapPageComponent,
    RoadMapCountdownComponent,
    ModalCollectAllCatCoinsComponent,
    ModalConfirmDarkRitualComponent,
    ModalConfirmKlayDarkRitualComponent,
    HotCatComponent,
    DiscordVerificationComponent,
    DobermanComponent,
    RoomerClubComponent,
    MongzComponent,
    CollectorDragonComponent,
    MemeTherapyComponent,
    JustplayanythingComponent,
    Kepler452bComponent,
    WhitelistInfoComponent,
    ModalTransferCatsComponent,
    CouponTestComponent,
    MyOtherComponent,
    OtherItemPresentationCardComponent,
    EventsComponent,
    SandboxComponent,
    ZodiacComponent,
    MyMeYouComponent,
    MeYouMintingComponent,
    CatTiffanyComponent,
    GrinderCatComponent,
    CatTownNovelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    CatStoriesModule,
    MatCheckboxModule,
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    }),
  ],
  providers: [{ provide: Window, useValue: window }],
  bootstrap: [AppComponent],
})
export class AppModule {}
