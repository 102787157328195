import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-road-map',
  templateUrl: './road-map.component.html',
  styleUrls: ['./road-map.component.scss']
})
export class RoadMapComponent implements OnInit {

  @Input() completed: boolean = false;
  @Input() task: string = "";
  @Input() secretTask: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
