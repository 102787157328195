import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import meYouOracleAbi from '../../contract/contract-me-you-oracle.json';
import meYouMinerAbi from '../../contract/contract-me-you-miner.json';
import meYouUtils from '../../contract/contract-me-you-utils.abi.json';
import { KaikasService } from './kaikas.service';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import coinAbi from '../../contract/contract-coin.abi.json';
import { HttpClient } from '@angular/common/http';

export interface IHeroCat {
  attributes: IHeroCatAttributes[],
  image: string,
  name: string,
  description: string,
  number?: string,
}

interface IHeroCatAttributes {
  trait_type: string,
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class MeYouService {
  constructor(
    private window: Window,
    private kaikasService: KaikasService,
    private httpClient: HttpClient,
  ) { }

  getHeroCat(id: number|string): Observable<any> {
    return this.httpClient.get(environment.meYouTokenUrl + '/k-me-you/' + id);
  }




  private userItems = new ReplaySubject<any[]>(1);

  get myItems() {
    return this.userItems.asObservable();
  }

  public async fetchItems():Promise<void> {
    if (this.kaikasService.loginInfo.isConnected == false) {
      this.userItems.next([]);
      return;
    }

    let caver = this.kaikasService.getCaver();
    let contract =  new caver.klay.Contract(meYouUtils, environment.contractMeYouUtils, { gasPrice: '25000000000' });
    let retData:any = await contract.call("allMeYouIds", this.kaikasService.loginInfo.address);
    let ret:any[] = [];
    for (let i=0; i<retData.length; i++) {
      ret.push({catId: retData[i]})
    }
    this.userItems.next(ret);
  }



  async buyWithCatc(walletAddress: string, amount: number) {
    let kaikas = (this.window as any).klaytn;
    let caver = this.kaikasService.getCaver();
    let contract =  new caver.klay.Contract(meYouMinerAbi, environment.contractMeYouMiner, { gasPrice: '25000000000' });
    let method = contract.methods.mintPublicCatc(amount);
    (this.window as any).method = method;
    let estimatedGas = await method.estimateGas({from: walletAddress, nonce: null});
    await method.send({from: walletAddress, gas: estimatedGas});
  }

  async buyWithKlay(walletAddress:string, amount: number, amountKlay: number) {
    let kaikas = (this.window as any).klaytn;
    let caver = this.kaikasService.getCaver();
    let value = this.kaikasService.toWei(amountKlay);
    let contract =  new caver.klay.Contract(meYouMinerAbi, environment.contractMeYouMiner, { gasPrice: '25000000000' });
    let method = contract.methods.mintPublicKlay(amount);
    (this.window as any).method = method;
    let estimatedGas = await method.estimateGas({from: walletAddress, value: value, nonce: null});
    await method.send({from: walletAddress, value: value, gas: estimatedGas});
  }

  async buyWithKlayWhiteList(walletAddress:string, amount: number, amountKlay: number) {
    let kaikas = (this.window as any).klaytn;
    let caver = this.kaikasService.getCaver();
    let value = this.kaikasService.toWei(amountKlay);
    let contract =  new caver.klay.Contract(meYouMinerAbi, environment.contractMeYouMiner, { gasPrice: '25000000000' });
    let method = contract.methods.mintPublicWhiteList(amount);
    (this.window as any).method = method;
    let estimatedGas = await method.estimateGas({from: walletAddress, value: value, nonce: null});
    await method.send({from: walletAddress, value: value, gas: estimatedGas});
  }

  async buyWithKlayPublic(walletAddress:string, amount: number, amountKlay: number) {
    let kaikas = (this.window as any).klaytn;
    let caver = this.kaikasService.getCaver();
    let value = this.kaikasService.toWei(amountKlay);
    let contract =  new caver.klay.Contract(meYouMinerAbi, environment.contractMeYouMiner, { gasPrice: '25000000000' });
    let method = contract.methods.mintPublicPublic(amount);
    (this.window as any).method = method;
    let estimatedGas = await method.estimateGas({from: walletAddress, value: value, nonce: null});
    await method.send({from: walletAddress, value: value, gas: estimatedGas});
  }


  private statusSubcription = new ReplaySubject<any>(1);
  private miningStatusSubcription = new ReplaySubject<any>(1);

  get status() {
    return this.statusSubcription.asObservable();
  }

  get miningStatus() {
    return this.miningStatusSubcription.asObservable();
  }

  public async fetchStatus():Promise<void> {
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe(async (loginInfo)=>{
      if (loginInfo.isConnected == false) {
        this.statusSubcription.next({og0: false, og1: false, whiteList:false, freeMint: 0});
        return;
      }

      let caver = this.kaikasService.getCaver();
      let contract =  new caver.klay.Contract(meYouOracleAbi, environment.contractMeYouOracle, { gasPrice: '25000000000' });
      let retData:any = await contract.call("getStatus", loginInfo.address);
      let ret:any = {og0: false, og1: false, whiteList:false, freeMint: 0};
      ret.og0 = (retData[1]);
      ret.og1 = (retData[2]);
      ret.whiteList = (retData[3]);
      ret.freeMint = (+retData[0]);
      this.statusSubcription.next(ret);
    });
  }

  public async fetchMiningStatus():Promise<void> {
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe(async (loginInfo)=>{
      if (loginInfo.isConnected == false) {
        this.miningStatusSubcription.next({klayPrice: 0, catcPrice:0, whiteListPrice: 0, publicPrice:0, secBeforeStart:10, klayMeYouLeft: 0, catcMeYouLeft: 0, whiteListLeft:0, publicLeft:0});
        return;
      }

      let caver = this.kaikasService.getCaver();
      let contract =  new caver.klay.Contract(meYouMinerAbi, environment.contractMeYouMiner, { gasPrice: '25000000000' });
      let retData:any = await contract.call("getStatus");

      let ret:any = {klayPrice: 0, catcPrice:0, whiteListPrice: 0, publicPrice:0, secBeforeStart:10, klayMeYouLeft: 0, catcMeYouLeft: 0, whiteListLeft:0, publicLeft:0};
      ret.klayPrice = this.kaikasService.fromWei(retData[0]);
      ret.catcPrice = this.kaikasService.fromWei(retData[1]);
      ret.whiteListPrice = this.kaikasService.fromWei(retData[2]);
      ret.publicPrice = this.kaikasService.fromWei(retData[3]);
      ret.secBeforeStart = (+retData[4]);
      ret.klayMeYouLeft = (+retData[5]);
      ret.catcMeYouLeft = (+retData[6]);
      ret.whiteListLeft = (+retData[7]);
      ret.publicLeft = (+retData[8]);
      this.miningStatusSubcription.next(ret);
    });
  }

  public makeAllowance(): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = this.kaikasService.toWei(500000000);
      let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
      let method = contract.methods.approve(environment.contractMeYouMiner, value);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public hasAllowanceToBid():Observable<boolean> {
    let caver = this.kaikasService.getCaver();

    let ret:Subject<boolean> = new Subject<boolean>();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{

      if (account.isConnected == true) {
        let value = this.kaikasService.toWei(100000000);
        let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
        contract.call("allowance", account.address, environment.contractMeYouMiner).then((allowance:any)=>{
          ret.next(Number(allowance) >= Number(value));
        });
      } else {
        ret.next(false);
      }
    });

    return ret.asObservable();
  }
}
