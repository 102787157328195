import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatRippleModule} from '@angular/material/core';
import {MatDivider, MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import { HeightSpacerComponent } from './components/height-spacer/height-spacer.component';
import { UniversalModalDialogComponent } from './components/universal-modal-dialog/universal-modal-dialog.component';
import { TextColorDirective } from './directives/text-color.directive';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DateAsSecondsToDaysPipe } from './pipes/dateAsSecondsToDays.pipe';
import { DateAsSecondsToDaysCountdownPipe } from './pipes/dateAsSecondsToDaysCountdown.pipe';
import { MarketCountdownComponent } from './components/market-countdown/market-countdown.component';
import { OhterNftComponent } from './components/ohter-nft/ohter-nft.component';
import { YoutubeVideoComponent } from './components/youtube-video/youtube-video.component';
import { LinkComponent } from './components/link/link.component';
import { RouterModule } from '@angular/router';
import { BiddingGameComponent } from './components/bidding-game/bidding-game.component';

@NgModule({
  declarations: [
    HeightSpacerComponent,
    UniversalModalDialogComponent,
    TextColorDirective,
    DateAsSecondsToDaysPipe,
    DateAsSecondsToDaysCountdownPipe,
    MarketCountdownComponent,
    OhterNftComponent,
    YoutubeVideoComponent,
    LinkComponent,
    BiddingGameComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterModule,
    MatTableModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  exports: [
    LinkComponent,
    HeightSpacerComponent,
    UniversalModalDialogComponent,
    TextColorDirective,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    FlexLayoutModule,
    MatCardModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule ,
    MatProgressBarModule,
    MatTableModule,
    MatDialogModule,
    MatRippleModule,
    MatDividerModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    DateAsSecondsToDaysPipe,
    DateAsSecondsToDaysCountdownPipe,
    MarketCountdownComponent
  ],
  providers: [
    DateAsSecondsToDaysPipe,
    DateAsSecondsToDaysCountdownPipe
  ]
})
export class SharedModule { }
