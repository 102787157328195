import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KaikasService } from 'src/app/core/services/kaikas.service';
import { environment } from 'src/environments/environment';
declare var Caver: any;

@Component({
  selector: 'app-discord-verification',
  templateUrl: './discord-verification.component.html',
  styleUrls: ['./discord-verification.component.scss']
})
export class DiscordVerificationComponent implements OnInit {

  code:string
  caver:any;
  address:string
  authenticated:boolean
  authUrl: string



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private window: Window,
    private httpClient: HttpClient,
    public kaikasService: KaikasService
  ) { }


  async ngOnInit() {
    this.authUrl = environment.discordAuthUrl;
    this.route.queryParams.subscribe(async params => {
      this.code = params.code;
      await this.kaikasService.silentLogin();
      this.address = await this.kaikasService.loginInfo.address;
      let klaytn = (this.window as any).klaytn;
      this.caver = new Caver(klaytn);
      if(this.code!=undefined){
        let signedCode = await	this.caver.klay.sign(this.code, this.address).then((signed:string)=>{
          return signed
        });
        let bodyPost ={
          code: this.code,
          address: this.address,
          signedAddress: signedCode
        };

        const optionsPost = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
          })
        }

        console.log("signedCode: ", signedCode);
        this.changeQuery();
        var apiUrl = environment.tokenUrl + '/discord-verification'
        let verRes = await this.httpClient.post(apiUrl, bodyPost, optionsPost).toPromise().then(response => {
            console.log("api response:",response);
            return response;
          }).catch((error)=>{console.log("error",error); return false})
        if(verRes === "OK"){
          this.authenticated = true;
        }
      }
    })

  }



  changeQuery() {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {  }});
  }
}
