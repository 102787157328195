<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
    <h1 class="main-title">Meme Therapy / OYO</h1>

    <div fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
      <div fxLayout="column">
          <div class="pp-card" >
            <a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;"> <img src="./assets/meme-therapy/oyo-logo3.png" class=""></a>
          </div>
          <p style="margin-left:5rem;"><a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;">memetherapy.io</a>
      </div>
      <div fxLayout="column">
          <div class="pp-card">
            <a href="https://oyonft.io/" target="_blank" style="text-decoration: underline;"> <img src="./assets/meme-therapy/oyo-logo2.png" class=""></a>
          </div>
          <p  style="margin-left:8rem;"><a href="https://oyonft.io/" target="_blank" style="text-decoration: underline;">oyonft.io</a>
      </div>

    </div>
  <ng-container *ngIf="status | async; else noGame; let st">
      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>10 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.25x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xaed7624aa218596be52080d68e3277ecb0160059/{{st.rewardIdLongest}}" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">OYO</p>
                    <p class="pp-title-o"><span class="pp-number">#{{st.rewardIdLongest}}</span></p>
                  </div>
                  <img src="https://api.cattownpunks.com/api/nft/image/0xaed7624aa218596be52080d68e3277ecb0160059/{{st.rewardIdLongest}}" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">OYO #{{st.rewardIdLongest}}</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xaed7624aa218596be52080d68e3277ecb0160059/{{st.rewardIdMaxPrice}}" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">OYO</p>
                    <p class="pp-title-o"> <span class="pp-number">#{{st.rewardIdMaxPrice}}</span></p>
                  </div>
                  <img src="https://api.cattownpunks.com/api/nft/image/0xaed7624aa218596be52080d68e3277ecb0160059/{{st.rewardIdMaxPrice}}" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">OYO #{{st.rewardIdMaxPrice}}</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>

    <a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;"> <img src="./assets/meme-therapy/oyo-logo3.png" class=""></a>
    <h3 style="margin-bottom: 10px; margin-top: 0px;">밈테라피, 세계 최초 디지털 테라피 토큰</h3>

    <p>
      일체유심조(一切唯心造) 모든 것은 마음먹기에 달렸다는 격언이 있습니다. <br>
  밈테라피 토큰은 구입 이후 지갑에 보관하거나, 토큰이 저장된 휴대전화를 보유하는 것만으로 건강이 회복되고, 자동차의 성능이 개선된다고 믿는 5.0세대 Meme Chain입니다.

    </p>

    <p>
      MMT 스왑은 <a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;">memetherapy.io</a> 에서 가능하며, 더 자세한 정보는 미디엄 페이지에서 만나보실수 있습니다 <br>
      (<a href="https://medium.com/@MemeTherapy_Official" target="_blank" style="text-decoration: underline;"> medium.com/@MemeTherapy_Official</a>).
    </p>

    <app-height-spacer height="10rem"></app-height-spacer>

    <h3 style="margin-bottom: 10px; margin-top: 0px;">Meme Therapy, World's First Digital Token Therapy</h3>

    <p>There is a proverb 'Everything depends on the mind', <br>
      Meme Therapy Token(MMT) is a Fifth Generation Meme Chain that by just holding it in your digital wallet or phone, will provide you a healthy life and perhaps improve the performance of your car as well.

    </p>

    <p>
      For more information on how to swap MMT please visit our website at <a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;">memetherapy.io</a> or visit our Medium (<a href="https://medium.com/@MemeTherapy_Official" target="_blank" style="text-decoration: underline;"> medium.com/@MemeTherapy_Official</a>) for more detailed information.
    </p>

    <app-height-spacer height="10rem"></app-height-spacer>

    <a href="https://oyonft.io/" target="_blank" style="text-decoration: underline;"> <img src="./assets/meme-therapy/oyo-logo2.png" class=""></a>
    <h3 style="margin-bottom: 10px; margin-top: 0px;">밈테라피의 첫번째 NFT 프로젝트 “OYO”</h3>

    <p>OYO는 문화재 수리기술자, 일러스트레이터인 작가 ‘오야추’가 사소한 디테일까지 철저한 역사적 고증을 거쳐 탄생시킨 9,999개의 아트피스입니다.<br>
      OYO를 통해 역사의 한 조각을 소장할 수 있으며, 작가의 장인정신과 밈테라피의 기술력은 여러분의 마음과 지갑에 “테라피”를 선물합니다.
    </p>

    <app-height-spacer height="10rem"></app-height-spacer>
    <h3 style="margin-bottom: 10px; margin-top: 0px;">The First Meme Therapy NFT Project, "OYO"</h3>

    <p>Oyachu, a Cultural Heritage Repair Engineer and Illustrator, created the 9,999 pieces of art with thorough historical verification.<br>
      With the combination of the artist's craftmanship and Meme Therapy's technology, you will not only own a piece of history with OYO, but also receive as gift the "therapy" needed for your heart and wallet.
    </p>






    <br>
    <p><a href="https://memetherapy.io/" target="_blank" style="text-decoration: underline;">Meme Therapy Official Website</a></p>
    <p><a href="https://1cent.kr/" target="_blank" style="text-decoration: underline;">Meme Therapy Official Community Website</a></p>
    <p><a href="https://oyonft.io/" target="_blank" style="text-decoration: underline;">OYO NFT Official Website</a></p>
    <p><a href="https://t.me/MemeTherapyOfficial" target="_blank" style="text-decoration: underline;">Official Telegram</a></p>
    <p><a href="https://medium.com/@MemeTherapy_Official" target="_blank" style="text-decoration: underline;">Official Medium</a></p>
    <p><a href="https://twitter.com/MemeTherapy_" target="_blank" style="text-decoration: underline;">Official Twitter</a></p>
    <p><a href="https://linktr.ee/MemeTherapy" target="_blank" style="text-decoration: underline;">More links</a></p>




    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
