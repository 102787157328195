import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import gameAbi from '../../contract/contract-game.abi.json';
import nftAbi from '../../contract/contract-nft.abi.json';
import { KaikasService } from './kaikas.service';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';




@Injectable({
  providedIn: 'root'
})
export class TransferService {
  constructor(
      private window: Window,
      private kaikasService: KaikasService,
      private snackBar: MatSnackBar,
  ) { }

  public makeAllowance(): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(nftAbi, environment.contractNft, { gasPrice: '25000000000' });

      let method = contract.methods.setApprovalForAll(environment.contractGame, true);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public getValidAddress(addr:string) {
    let caver = this.kaikasService.getCaver();
    try {
      return caver.utils.toChecksumAddress(addr)
    } catch(e) {
      return "";
    }
  }

  public hasAllowanceToTransfer():Observable<boolean> {
    let caver = this.kaikasService.getCaver();

    let ret:Subject<boolean> = new Subject<boolean>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      if (account.isConnected == true) {
        let contract =  new caver.klay.Contract(nftAbi, environment.contractNft, { gasPrice: '25000000000' });
        contract.call("isApprovedForAll", account.address, environment.contractGame).then((allowance:boolean)=>{
          ret.next(allowance);
        });
      } else {
        ret.next(false);
      }
    });
    return ret.asObservable();
  }

  public transferCats(cats: number[], destinationAddress:string) {
    let caver = this.kaikasService.getCaver();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });

      let method = contract.methods.batchTransfer(cats, destinationAddress);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.snackBar.openFromComponent(ResponseSnackbarComponent, {
          horizontalPosition: "center",
          verticalPosition: "bottom",
          duration: 6000,
          data: "REFRESHING... PLEASE WAIT"
        });
        this.kaikasService.fetchItems();
      });
    });
  }


}
