import { Component, OnInit } from '@angular/core';
import { CatService } from '../core/services/cat.service';
import { IMarketCountdown } from '../shared/components/market-countdown/market-countdown.component';
import { KaikasService } from '../core/services/kaikas.service';
import { ICatItem } from '../features/cat-stories/cat-story/cat-story.component';



@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public catStories: ICatItem[] = [];


/*   public mintPriceAuction: IMarketCountdown = {
    title: "MINTING PRICE CHANGE",
    text: "We will increase minting price 3 Klay.",
    countdown: new Date(Date.UTC(2020, 10, 17, 14, 0))
  } */

  public catCoinPresale!: IMarketCountdown;

  public catCoinSecondsLeft: number = 0;

  constructor(
    private catService:CatService,
    public kaikas: KaikasService
    ) { }
  public lastIndexes:string[] = [];
  async ngOnInit(): Promise<void> {
    this.catService.getCatStories().subscribe(data => this.catStories = data);

    let catCoinSeconds: any;
    this.kaikas.getPresaleInfo().subscribe(data => {
      catCoinSeconds = data.secBeforeStart;
      this.catCoinSecondsLeft = data.secBeforeStart;
    }) ;

    this.catCoinPresale = {
      title: "CATCOIN PRESALE!",
      text: "CatCoin presale will be avaliable",
      secondsTillCountdown: catCoinSeconds
    }

    // this.catService.getLastMintedId().subscribe((lasts:string)=>{
    //   let last:number = +lasts;
    //   for (let i = last-1; i> (last-7); i--) {
    //     this.lastIndexes.push(i + "");
    //   }
    // });
  }

}
