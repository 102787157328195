<div class="footer" fxLayout="column" fxLayoutAlign="center center">
  <app-height-spacer height="1rem"></app-height-spacer>
    <div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem">
      <p class="f-title">CAT TOWN PUNKS</p>

      <div fxLayout="row" fxLayout.lt-sm="column">
        <p class="f-contact">Contact Us: <a class="f-mail" href="mailto:info@cattownpunks.com">info@cattownpunks.com</a></p>
      </div>

      <div fxLayout="row" fxLayoutGap="1.5rem">
        <a href="https://discord.gg/3SdZ7uR76j" target="_blank"><img src="assets/social_discort.png" alt="social_discort"></a>
        <a href="https://twitter.com/CatTownPunks" target="_blank"><img src="assets/social_twitter.png" alt="social_twitter"></a>
        <a href="https://open.kakao.com/o/gmjNxjCd" target="_blank">
          <mat-icon svgIcon="kakao" style="height: 49px; width: 49px;"></mat-icon>
        </a>
      </div>
      <app-height-spacer height="1rem"></app-height-spacer>
      <small class="f-disclamer">Disclaimer</small>
        <small class="f-disclamer">
          Crypto investments are subject to market risks, please read the offer document carefully before investing your funds.
          Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general
          market commentary, and does not constitute investment advice.
          You are responsible for all the risks and financial resources you use on CatTownpunks.com platform for the chose.
          You should not engage unless you fully understand the nature of high risk and volatility on cryptocurrencies markets and
          you are entering into on your exposure to loss. If you do not fully understand these risks you should seek independent advice
          from your financial advisor or other crypto experts who are willing to take part of your risk on their behalf.
        </small>
      <app-height-spacer height="1rem"></app-height-spacer>
    </div>
</div>
