import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { PageService } from 'src/app/core/services/page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'demo-road-map-page',
  templateUrl: './road-map-page.component.html',
  styleUrls: ['./road-map-page.component.scss']
})
export class RoadMapPageComponent implements OnInit {

  public content: string;
  constructor(
    private pageService: PageService,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let refresh:string|null = this.route.snapshot.queryParamMap.get('refresh');
    this.pageService.getPage(environment.pages.roadmap, (refresh=="true")).pipe(take(1)).subscribe((data)=>{
      this.content = data;
    });
  }

}

