<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
    <h1 class="main-title">justplayanything</h1>
    <a href="https://opensea.io/collection/justplayanything" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/justplayanything/jpa-logo.png" alt="friendly-sheep"><img></a>
  <ng-container *ngIf="status | async; else noGame; let st">
      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>5 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.25x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x7400df4339b47890a3ebac3bd4e75e40596e6746/536" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o2">justplayanything</p>
                    <p class="pp-title-o"><span class="pp-number">#431</span></p>
                  </div>
                  <img src="./assets/justplayanything/jpa431.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Sin-gu #431</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0x7400df4339b47890a3ebac3bd4e75e40596e6746/535" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o2">justplayanything</p>
                    <p class="pp-title-o"> <span class="pp-number">#425</span></p>
                  </div>
                  <img src="./assets/justplayanything/jpa425.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Ji-hak #425</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>

    <h3 style="margin-bottom: 10px; margin-top: 0px;">동네친구 소개</h3>
    <p>
      안녕하세요 동네친구 프로젝트를 진행하고 있는 NFT아티스트 저플 입니다. 현재 국내의 총
      700명의 친구들을 대한민국의 자치구마다 약 20명씩 만들어가는 중이며 현재 가나다 순으로
      하여 서울 양천구 친구들을 매일 리스팅 진행하고 있습니다. 동네친구는 각 친구들마다의 직업,
      취미,좋아하는 음식이 다르며 한글과 영어로 번역해놓은 설명이 NFT에 포함되어있습니다. 등
      급은 일반,레어,에픽,레전더리 총 네가지로 분류가 되며 추후 NFT 성장에 따라 등급별 차등 이
      벤트를 열 계획입니다. 처음에는 제가 주변에 친구가 별로 없어서 내 친구들을 만들어보자라는
      마음으로 가볍게 시작하였지만 700명을 완료하기 전 까지 막중한 책임감으로 임하고 있으며
      국내 동네친구가 마무리 되고 난 후에는 해외 동네친구를 제작할 계획입니다.
    </p>






    <br>
    <p><a href="https://open.kakao.com/o/gy3sbKOd" target="_blank" style="text-decoration: underline;">커뮤니티</a></p>
    <p><a href="https://opensea.io/collection/justplayanything" target="_blank" style="text-decoration: underline;">오픈씨</a></p>
    <p><a href="https://linktr.ee/justplayanything" target="_blank" style="text-decoration: underline;">추가정보</a></p>





    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
