import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import nftAbi from '../../contract/contract-nft.abi.json';
import coinAbi from '../../contract/contract-coin.abi.json';
import shopAbi from '../../contract/contract-shop.abi.json';
import gameAbi from '../../contract/contract-game.abi.json';
import presaleAbi from '../../contract/contract-presale.abi.json';
import oracleAbi from '../../contract/contract-oracle.abi.json';
import { LoaderService } from './loader.service';
import { environment } from '../../../environments/environment';
import { map, take } from 'rxjs/operators';
import { CatService } from './cat.service';
import { CatShopService } from 'src/app/features/cat-profile/cat-shop.service';

declare var Caver: any;

export interface LoginInfo {
  isConnected: boolean;
  address: string;
  catCoins: number;
  klay:number;
  lastError:String;
}

export interface CatShopInfo {
  priceList: ItemPrice[]
}

interface ItemPrice {
  price: number;
  itemId: number;
}

interface CatItemsInfo {
  skills: number[];
  sackItemId?: number;
  miningMultiplier?: number;
}

export interface PresaleInfo {
  price: number,
  startBlock: number,
  coinsLeft: number,
  gotDate: string,
  secBeforeStart: number
}

export interface IKaikasTransaction {
  transactionSuccessful: boolean;
  errorDescription?: string;
}

export interface ITop50CatsData {
  amount: number;
  catIds: number[];
}

@Injectable({
  providedIn: 'root'
})
export class KaikasService {
  constructor(
      private window: Window,
      private loaderService: LoaderService,
      private catService: CatService,
      private catShopService: CatShopService,
      private httpClient: HttpClient,
  ) {
    this.userLoginInfo.next({isConnected:false, address:"", catCoins:0, klay:0, lastError: ""});
    if (this.kaikas != null) {
      this.kaikas.on("accountsChanged", (accs: string[]) => {
        this.silentLogin();
      });
    }
  }

  private userLoginInfo = new ReplaySubject<LoginInfo>(1);
  private userItems = new ReplaySubject<any[]>(1);
  private catShopInfo:ReplaySubject<CatShopInfo>[] = [];
  private catMiningInfo:ReplaySubject<number>[] = [];
  private catSackLimit:ReplaySubject<number>[] = [];
  private catItems:ReplaySubject<CatItemsInfo>[] = [];
  private presaleInfo = new ReplaySubject<PresaleInfo>(1);
  private allCatsMined = new ReplaySubject<number>(1);
  public loginInfo:LoginInfo = {isConnected:false, address:"", catCoins:0, klay:0, lastError: ""};

  get myItems() {
    this.loaderService.isLoading.next(true);
    return this.userItems.asObservable();
  }

  public getCatShopInfo(catId:number) {
    if (this.catShopInfo[catId] == null) {
      this.refreshCatShopInfo(catId);
    }
    return this.catShopInfo[catId];
  }

  public getCatItemsInfo(catId:number) {
    if (this.catItems[catId] == null) {
      this.refreshCatItemsInfo(catId);
    }
    return this.catItems[catId];
  }

  public getCatMintedInfo(catId:number) {
    if (this.catMiningInfo[catId] == null) {
      this.refreshCatMiningInfo(catId);
    }
    return this.catMiningInfo[catId];
  }

  public getCatSackLimit(catId:number) {
    if (this.catSackLimit[catId] == null) {
      this.refreshCatSackLimit(catId);
    }
    return this.catSackLimit[catId];
  }

  public getPresaleInfo() {
    this.refreshPresaleInfo();
    return this.presaleInfo.asObservable();
  }

  public getTop50CatsMined() {
    return this.myItems.pipe( map(res => {
      let ret:ITop50CatsData = {amount:0, catIds:[]};
      for (let i =0; i<50; i++) {
        if (res[i]) {
          ret.amount += Number(res[i].amountMinted);
          ret.catIds.push(Number(res[i].catId));
        }
      }
      return ret;
    }));
  }

  public getAllCatsMined() {
    this.refreshAllCatsMined();
    return this.allCatsMined.asObservable();
  }

  public getCaver():any {
    let caver = new Caver(this.kaikas);
    return caver;
  }

  public buyPresale(amount:number) {
    let caver = new Caver(this.kaikas);

    let requests:Observable<any>[] = [
      this.presaleInfo,
      this.loginInfoObserver
    ];
    combineLatest(requests).pipe(take(1)).subscribe(([info, account])=>{
      let value = Caver.utils.toWei(info.price, "ether") * amount;
      let contract =  new caver.klay.Contract(presaleAbi, environment.contractPresale, { gasPrice: '25000000000' });

      let method = contract.methods.buyPresale(Caver.utils.toWei(amount + "", "ether"));
      method.estimateGas({from: account.address, value: value, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, value: value, gas: estimatedGas});
      }).then(()=>{
        this.refreshPresaleInfo();
      });
    });
  }

  public refreshCatMiningInfo(catId:number) {
    if (this.catMiningInfo[catId] == null) {
      this.catMiningInfo[catId] = new ReplaySubject<number>(1);
    }
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
    contract.call("amountMined", catId).then((amountMinted:any)=>{
      this.catMiningInfo[catId].next(Caver.utils.fromWei(amountMinted));
    });
  }

  public getNextHalvingCountdownApi(): Observable<string> {
    return this.httpClient.get( environment.apiUrl + "/api/coin/halving", {responseType: 'text'});
  }

  public getNextHalvingCountdown(): Observable<number> {
    let ret:Subject<number> = new Subject<number>();
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
    contract.call("nextHalvingBlock").then((data:any)=>{
      ret.next(data[2]);
      ret.complete();
    });
    return ret.asObservable();
  }


  public toWei(amount: number) {
    return Caver.utils.toWei(amount+"", "ether");
  }

  public fromWei(amount: number) {
    return Caver.utils.fromWei(amount)
  }

  public refreshCatSackLimit(catId:number) {
    if (this.catSackLimit[catId] == null) {
      this.catSackLimit[catId] = new ReplaySubject<number>(1);
    }
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
    contract.call("getSackSize", catId).then((sackLimit:any)=>{
      this.catSackLimit[catId].next(sackLimit);
    });
  }

  public refreshPresaleInfo() {
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(presaleAbi, environment.contractPresale, { gasPrice: '25000000000' });
    contract.call("info").then((values:any)=>{
      let ret:PresaleInfo = {
        coinsLeft: Caver.utils.fromWei(values["0"]),
        startBlock: values["1"],
        price: Caver.utils.fromWei(values["2"]),
        secBeforeStart: values["3"],
        gotDate: Date()
      }
      this.presaleInfo.next(ret);
    });
  }

  public refreshAllCatsMined() {
    this.loginInfoObserver.pipe(take(1)).subscribe((info:LoginInfo) => {
      if (this.loginInfo.isConnected == true) {
        let caver = new Caver(this.kaikas);
        let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
        contract.call("amountMintedAllCats", info.address).then((value: number)=>{
          this.allCatsMined.next(Caver.utils.fromWei(value));
        });
      }
    });
  }

  public refreshCatItemsInfo(catId:number) {
    if (this.catItems[catId] == null) {
      this.catItems[catId] = new ReplaySubject<CatItemsInfo>(1);
    }
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(oracleAbi, environment.contractOracle, { gasPrice: '25000000000' });
    contract.call("getItems", catId).then((data:number[])=>{
      let ret:CatItemsInfo = {
        miningMultiplier: undefined,
        sackItemId: undefined,
        skills: []
      }
      let maxSkillItem:number = 0;
      let skillItems = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
      let sackItems = [1,2,3,4,5];
      for(let item in data) {
        if (skillItems.includes(Number(data[item]))) {
          if (data[item] > maxSkillItem) {
            maxSkillItem = data[item];
          }
        }
        if (sackItems.includes(Number(data[item]))) {
          ret.sackItemId = data[item];
        }
      }

      if (ret.sackItemId != undefined) {
        ret.miningMultiplier = 1;
      }
      switch (Number(maxSkillItem)) {
        case 11: ret.miningMultiplier = 1.5; break;
        case 12: ret.miningMultiplier = 1.75; break;
        case 13: ret.miningMultiplier = 2; break;
        case 14: ret.miningMultiplier = 2.5; break;
        case 15: ret.miningMultiplier = 3; break;
        case 16: ret.miningMultiplier = 4; break;
        case 17: ret.miningMultiplier = 5; break;
        case 18: ret.miningMultiplier = 6; break;
        case 19: ret.miningMultiplier = 7; break;
        case 20: ret.miningMultiplier = 10; break;
        case 21: ret.miningMultiplier = 15; break;
      }
      if (maxSkillItem != 0) {
        for (let skill in skillItems) {
          ret.skills.push(skillItems[skill]);
          if (Number(maxSkillItem) == skillItems[skill]) {
            break;
          }

        }
      }
      this.catItems[catId].next(ret);
    });
  }

  public refreshCatShopInfo(catId:number) {
    if (this.catShopInfo[catId] == null) {
      this.catShopInfo[catId] = new ReplaySubject<CatShopInfo>(1);
    }
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(shopAbi, environment.contractShop, { gasPrice: '25000000000' });
    contract.call("priceListForCat", catId).then((data:any)=>{
      let csi:CatShopInfo = {
        priceList: []
      }
      let items = data["0"];
      let prices = data["1"];
      for(let i=0; i<items.length; i++) {
        if(prices[i] != 0) {
          csi.priceList.push({
            price: Caver.utils.fromWei(prices[i]),
            itemId: items[i]
          });
        }
      }
      let allItems = [1,2,3,4,5,11,12,13,14,15,16,17,18,19,20,21];
      for (let aItem in allItems) {
        let isFound = false;
        for (let item in csi.priceList) {
          if (csi.priceList[item].itemId == allItems[aItem]) {
            isFound = true;
          }
        }
        if (!isFound) {
          csi.priceList.push({price:0, itemId:allItems[aItem]});
        }
      }
      this.catShopInfo[catId].next(csi);
    });
  }

  public async fetchItems():Promise<void> {
    if (this.loginInfo.isConnected == false) {
      this.userItems.next([]);
      return;
    }

    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
    let retData:any = await contract.call("amountMintedEveryCat", this.loginInfo.address);
    let ret:any[] = [];
    for (let i=0; i<retData[0].length; i++) {
      ret.push({catId: retData[0][i], amountMinted: this.fromWei(retData[1][i]), checked: false})
    }
    ret.sort((a,b) => Number(b.amountMinted) - Number(a.amountMinted));
    this.userItems.next(ret);

    if(retData[0].length) {
      this.loaderService.isLoading.next(false);
    } else {
      this.loaderService.isLoading.next(true);
    }
  }

  get loginInfoObserver() {
    return this.userLoginInfo.asObservable();
  }

  get kaikas() {
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      return (this.window as any).klaytn;
    }
    return null;
  }

  private changeLoginInfo(newValue:LoginInfo) {
    if (newValue.isConnected == false) {
      newValue.klay = 0;
      newValue.catCoins = 0;
    }
    this.loginInfo = newValue;
    this.userLoginInfo.next(newValue);
  }

  async silentLogin(): Promise<void> {
    let kaikas = null;
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      kaikas = (this.window as any).klaytn;
    } else {
      this.changeLoginInfo(this.loginInfo);
      return;
    }
    if (kaikas.networkVersion != environment.chanId) {
      this.changeLoginInfo(this.loginInfo);
      return;
    }
    if (kaikas.isConnected() && await kaikas._kaikas.isApproved() && await kaikas._kaikas.isUnlocked()) {
      try {
        const accounts = await kaikas.enable()
        this.loginInfo.address = accounts[0];
      } catch (error) {
        this.changeLoginInfo(this.loginInfo);
        return;
      }
      this.refreshCoinBalance();
    }
  }

  async login(): Promise<void> {
    let kaikas = null;
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      kaikas = (this.window as any).klaytn;
    } else {
      this.loginInfo.isConnected = false;
      this.loginInfo.lastError = "KAIKAS_NOT_INSTALLED";
      this.changeLoginInfo(this.loginInfo);
      return;
    }
    if (kaikas.networkVersion != environment.chanId) {
      console.log("invalid network!");
      this.loginInfo.isConnected = false;
      this.loginInfo.lastError = "KAIKAS_INVALID_NETWORK";
      this.changeLoginInfo(this.loginInfo);
      return;
    }

    try {
      const accounts = await kaikas.enable();
      this.loginInfo.address =  accounts[0];
    } catch (error) {
      this.loginInfo.isConnected = false;
      this.loginInfo.lastError = "KAIKAS_ERROR_CONNECTING";
      this.changeLoginInfo(this.loginInfo);
      return;
    }
    this.refreshCoinBalance();
  }

  private async refreshCoinBalance() {
    let kaikas = (this.window as any).klaytn;
    let result = this.loginInfo;
    //result.address = kaikas.selectedAddress;
    result.isConnected = (result.address != undefined);
    result.lastError = "";
    this.changeLoginInfo(result);
    if (result.isConnected == false) return;
    let caver = new Caver(this.kaikas);
    let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
    let balance = await contract.call("balanceOf", result.address);
    result.catCoins = Number(Caver.utils.fromWei(balance));
    this.changeLoginInfo(result);
    let klayBalance = await caver.klay.getBalance(result.address);
    result.klay = Number(Caver.utils.fromWei(klayBalance));
    this.changeLoginInfo(result);
  }

  public shortAddress(addr:string) {
    return addr.substr(0, 5) + "..." + addr.substr(addr.length-5, 5);
  }

  public isAllowedToBuy(amount:number):Observable<boolean> {
    let caver = new Caver(this.kaikas);

    let ret:Subject<boolean> = new Subject<boolean>();

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = Caver.utils.toWei(amount, "ether");
      let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
      contract.call("allowance", account.address, environment.contractShop).then((allowance:any)=>{
        ret.next(Number(allowance) >= Number(value));
      });
    });
    return ret.asObservable();
  }

  public makeAllowance(amount: number): Observable<void> {
    let caver = new Caver(this.kaikas);

    let ret:Subject<void> = new Subject<void>();

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = Caver.utils.toWei(amount+"", "ether");
      let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });

      let method = contract.methods.approve(environment.contractShop, value);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public collectMined(catId: number): Observable<void> {
    let caver = new Caver(this.kaikas);

    let ret:Subject<void> = new Subject<void>();

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });

      let method = contract.methods.collectMiningReward(catId);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.refreshCatMiningInfo(catId);
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public collectSelected(cats: number[]): Observable<IKaikasTransaction> {
    let caver = new Caver(this.kaikas);

    let ret:Subject<IKaikasTransaction> = new Subject<IKaikasTransaction>();

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, { gasPrice: '25000000000' });
      let method = contract.methods.collectMiningRewardForCats(cats);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).catch((err: any) => {
        console.log(err);
        return ret.next({
          transactionSuccessful: false,
          errorDescription: err
        })
      }).then(()=>{
        ret.next({transactionSuccessful: true});
      });
    });
    return ret.asObservable();
  }

  public collectAll(): Observable<IKaikasTransaction> {
    let caver = new Caver(this.kaikas);

    let ret:Subject<IKaikasTransaction> = new Subject<IKaikasTransaction>();

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(gameAbi, environment.contractGame, {gasPrice: '55500000000000' });

      let method = contract.methods.collectMiningRewardForAllCats();
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).catch((err: any) => {
        return ret.next({
          transactionSuccessful: false,
          errorDescription: err
        })
      }).then(()=>{
        ret.next({transactionSuccessful: true});
      });
    });
    return ret.asObservable();
  }

  public buyItemCatCoin(catId:number, itemId:number) {
    let caver = new Caver(this.kaikas);

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(shopAbi, environment.contractShop, { gasPrice: '25000000000' });

      let method = contract.methods.buyItem(catId, itemId);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.catService.fixMetadata(catId).pipe(take(1)).subscribe(v=>{
          this.refreshCatShopInfo(catId);
          this.refreshCatSackLimit(catId);
          this.refreshCatItemsInfo(catId);
          this.catService.refreshHeroCatObservable(catId);
          this.isMiningSkillUpgraded(itemId);
        });
      });
    });
  }

  public buyItemKlay(catId:number, itemId:number, price:number) {
    let caver = new Caver(this.kaikas);

    this.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(shopAbi, environment.contractShop, { gasPrice: '25000000000' });
      let value = Caver.utils.toWei(price+"", "ether");
      let method = contract.methods.buyItemKlay(catId, itemId);
      method.estimateGas({from: account.address, value: value, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, value: value, gas: estimatedGas});
      }).then(()=>{
        this.catService.fixMetadata(catId).pipe(take(1)).subscribe(v=>{
          this.refreshCatShopInfo(catId);
          this.refreshCatSackLimit(catId);
          this.refreshCatItemsInfo(catId);
          this.catService.refreshHeroCatObservable(catId);
          this.isMiningSkillUpgraded(itemId);
        });
      });
    });
  }

  isMiningSkillUpgraded(itemId: number) {
    const shopbagId: number[] = [1,2,3,4,5];
    if(shopbagId.filter(x => x ==  itemId)) {
      this.catShopService.changeImage("OK");
     // this.catShopService.changeImage(`Item bought: ${itemId}`);
    }
  }


}
