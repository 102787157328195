import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-response-snackbar',
  templateUrl: './response-snackbar.component.html',
  styleUrls: ['./response-snackbar.component.scss']
})
export class ResponseSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }

  ngOnInit() {
  }

}
