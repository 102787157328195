import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';
import { KaikasService, LoginInfo } from '../../core/services/kaikas.service';
import { LoaderService } from '../../core/services/loader.service';
import { FilterCatsService, ICatsFilter } from './filter-cats.service';
import { ModalTransferCatsComponent } from './modal-transfer-cats/modal-transfer-cats/modal-transfer-cats.component';


@Component({
  selector: 'app-my-cats',
  templateUrl: './my-cats.component.html',
  styleUrls: ['./my-cats.component.scss']
})
export class MyCatsComponent implements OnInit, OnChanges {

  constructor(
    public kaikas: KaikasService,
    private loader: LoaderService,
    private filterService: FilterCatsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
    ) {

   }
  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }

  rarityList: string[] = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];


  filterForm = new FormGroup({
    rarity: new FormControl(''),
    mining: new FormControl(''),
  });

  public isLoading: boolean = true;
  public myItems:any[] = [];
  private loaderSearch: string = "Searching for your cats ...";
  private loaderNoCats: string = "You have no cats on this profile.";

  public loaderMessage: string = this.loaderSearch;

  private onLoginSubscription?:Subscription;
  private onItemsFetchedSubscription?:Subscription;
  private onFilterSubscription?:Subscription;
  private onCheckChanged?:Subscription;
  private lastFetchedAddress:string = "";

  public collectingInProgress: boolean;
  public transferingInProgress: boolean;

  public selectedCats: number;
  public catcToBeCollected: number;

  ngOnInit(): void {

    this.loader.isLoading.pipe(delay(0)).subscribe((loading) =>{
      this.isLoading = loading
    });

    this.onItemsFetchedSubscription = this.kaikas.myItems.subscribe((items:string[])=>{
      this.myItems = items;
      if (items.length == 0) {
        this.loaderMessage = this.loaderNoCats;
      }
    })

    this.onLoginSubscription = this.kaikas.loginInfoObserver.subscribe((loginInfo:LoginInfo)=>{
      if (loginInfo.address != this.lastFetchedAddress) {
        this.lastFetchedAddress = loginInfo.address;
        this.kaikas.fetchItems();
      }
    });

    this.onCheckChanged = this.filterService.checkChanged.subscribe((val:string)=>{
      this.selectedCats = 0;
      this.catcToBeCollected = 0;
      for(let i=0; i<this.myItems.length; i++) {
        if (this.myItems[i].checked) {
          this.selectedCats++;
          this.catcToBeCollected+= Number(this.myItems[i].amountMinted);
        }
      }
    });

    this.filterForm.valueChanges.subscribe( x => {
      this.filterService.changeFilter(x.rarity, x.mining);
    })

    this.onFilterSubscription = this.filterService.currentFilter.subscribe((filter:ICatsFilter)=>{
      if (filter.checkboxes == false) {
        this.collectingInProgress = false;
        this.transferingInProgress = false;
      } else {
        if (filter.actionType == "COLLECT")
          this.collectingInProgress = true;
        if (filter.actionType == "TRANSFER")
          this.transferingInProgress = true;
      }
    });
    this.cancelCheckboxes();
  }

  private getSelectedCatIds() {
    let cats:number[] = [];
    for(let i=0; i<this.myItems.length; i++) {
      if (this.myItems[i].checked) {
        cats.push(this.myItems[i].catId);
      }
    }
    return cats;
  }

  completeTransfer() {

    const dialogRef = this.dialog.open(ModalTransferCatsComponent, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      data: this.getSelectedCatIds()
    });
    dialogRef.afterClosed().subscribe(result => {
        this.cancelCheckboxes();
    });
  }

  ngOnDestroy() {
    this.onLoginSubscription?.unsubscribe();
    this.onItemsFetchedSubscription?.unsubscribe();
    this.onFilterSubscription?.unsubscribe();
    this.onCheckChanged?.unsubscribe();
  }

  cancelCheckboxes() {
    this.filterService.hideCheckboxes();
  }

  completeCollecting() {
    this.kaikas.collectSelected(this.getSelectedCatIds()).pipe(take(1)).subscribe(()=>{
      this.cancelCheckboxes();
      this.snackBar.openFromComponent(ResponseSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        duration: 3000,
        data: "REFRESHING... PLEASE WAIT"
      });
      this.kaikas.fetchItems();
    });
  }
}
