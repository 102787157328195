<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">

    <h1 class="main-title">Klay Dobermann V2</h1>
    <a href="https://klaydobermann.io/" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/kdm/kdm-t.png" alt="friendly-dog"><img></a>
    <p><a href="https://klaydobermann.io/" target="_blank" style="text-decoration: underline;"> Klaydobermann.io </a>
    </p>
    <ng-container *ngIf="status | async; else noGame; let st">


      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>20 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.20x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xc5a8d28511e9ac5679f406b65f485f86a7ffe76a/1891" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">Klay Doberman V2</p>
                    <p class="pp-title-o"> <span class="pp-number">#6777</span></p>
                  </div>
                  <img src="./assets/kdm/kdm6777.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Klay Doberman V2 #6777</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xc5a8d28511e9ac5679f406b65f485f86a7ffe76a/1810" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">Klay Doberman V2</p>
                    <p class="pp-title-o"><span class="pp-number">#4449</span></p>
                  </div>
                  <img src="./assets/kdm/kdm4449.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Klay Doberman V2 #4449</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>

        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>
    <h2 style="margin-bottom: 10px; margin-top: 0px;">STORY</h2>

    <p>
      Klaydobermann은 도베르만을 모티브로 한 PFP 프로젝트입니다. 120장의 수작업 아트웍 V1을 토대로 1만장의 Generative PFP인 V2를 진행 중에 있습니다. 본 프로젝트는 우주 문명화와
      평화유지를 목표로 삼는 도베르만족과 관련된 세계관을 구축하고 있습니다.
    </p>

    <p>
      Klaydobermann은 PFP를 활용한 사업수익의 일부를 디파이풀이나 문화콘텐츠에 투자하며, 투자를 통해 발생한 수익은 홀더들에게 분배합니다. 세계관을 토대로 한 개발진행, 거버넌스를 토대로 한 투자진행,
      문화콘텐츠 투자의 확대, PFP 저작권의 온오프라인 활용방안 확대, 메타버스 진출 등의 로드맵을 그리며<br><br>
      Klaydobermann NFT의 가치상승을 목표로 삼고 있습니다.
    </p>

    <p>
      프로젝트 PFP 이미지 아래와 같으니 참고하시길 바랍니다.
    </p>

    <div fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
      <div class="pp-card" fxLayout="column">
        <img src="./assets/kdm/kdm-p1.png" class="" style="cursor: default;">
      </div>
      <div class="pp-card" fxLayout="column">
        <img src="./assets/kdm/kdm-p2.png" class=""  style="cursor: default;">
      </div>
    </div>

    <p>
      프로젝트팀으로는 총괄/CM으로 KDM, 기획/마케팅을 담당한 crypto_toktok, 아티스트 Doguro 작가, 개발팀으로 Ooju(<a href="https://ooju.xyz/"
        target="_blank" style="text-decoration: underline;">Ooju.xyz</a>)가 참여하고 있습니다.
    </p>

    <br>

    <p><a href="https://klaydobermann.io/" target="_blank" style="text-decoration: underline;">Klaydobermann.io</a></p>
    <p><a href="https://twitter.com/DobermannKlay" target="_blank" style="text-decoration: underline;">Twitter</a></p>
    <p><a href="https://open.kakao.com/o/giI3FXAd" target="_blank" style="text-decoration: underline;">Kakao</a></p>
    <p><a href="https://discord.gg/wEKsBUdUaf" target="_blank" style="text-decoration: underline;">Discord</a></p>




    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
